<template>
  <div v-if="workInProgress">
    <h1>WORK IN PROGRESS</h1>
  </div>
  <div v-else>
    <CCard>
      <CCardHeader>
        <h4>
          {{ showFilter }}({{ " Last Generated: " + lastGenerated }})
          <div class="float-right">
            Latest
            <b
              ><a @click="$router.push({ path: '/dashboard' })">Changelog</a></b
            >
          </div>
        </h4>
        <security-help :text="selectedtips" :selectedColumn="selectedColumn">
        </security-help>
      </CCardHeader>
      <CCardBody class="Resizable-KPIs Resizable-KPIs-Accounts">
        <CDataTable
          :items="computedItems"
          :fields="fields"
          items-per-page-select
          :items-per-page="10"
          :loading="isLoading"
          column-filter
          table-filter
          sorter
          hover
          striped
          small
          pagination
          clickableRows
          @row-clicked="rowClicked"
        >
          <template #AccountAlias="{ item }">
            <td class="Sticky-Column-KPI">
              {{ item.AccountAlias }}
            </td>
          </template>
          <template #score="{ item }">
            <td class="py-2" style="font-weight: 900">{{ item.Score }}</td>
          </template>
          <template #CERTIFIEDREPORT="{ item }">
            <show-score
              :score="getScore(item.CERTIFIEDREPORT, 'CERTIFIEDREPORT')"
              @show-hide="toggleRows('CERTIFIEDREPORT')"
              :toggle="toggle.CERTIFIEDREPORT"
            ></show-score>
          </template>
          <template #CERTIFIEDREPORT-Score="{ item }">
            <show-data
              :data="item.CERTIFIEDREPORT.Score"
              :FormatValueExceptions="false"
              :check="item.CERTIFIEDREPORT.Score === 0"
            ></show-data>
          </template>
          <template #MFA="{ item }">
            <td class="py-2">
              <CButton
                v-if="item.MFA !== null"
                :color="item.MFA === 1 ? 'success' : 'danger'"
                height="0.8rem"
                size="sm"
                >{{ item.MFA === 1 ? "V" : "X" }}</CButton
              >
              <CButton
                v-else
                size="sm"
                variant="outline"
                color="info"
                height="0.8rem"
              >
                {{ "N/A" }}
              </CButton>
            </td>
          </template>
          <template #CT="{ item }">
            <td class="py-2">
              <CButton
                v-if="item.CT !== null"
                :color="item.CT >= 1 ? 'success' : 'danger'"
                height="0.8rem"
                size="sm"
                >{{ item.CT >= 1 ? "V" : "X" }}</CButton
              >
              <CButton
                v-else
                size="sm"
                variant="outline"
                color="info"
                height="0.8rem"
              >
                {{ "N/A" }}
              </CButton>
            </td>
          </template>
          <template #TA="{ item }">
            <td class="py-2">
              <CButton
                v-if="item.TA !== null"
                :color="item.TA >= 1 ? 'success' : 'danger'"
                height="0.8rem"
                size="sm"
                >{{ item.TA >= 1 ? "V" : "X" }}</CButton
              >
              <CButton
                v-else
                size="sm"
                variant="outline"
                color="info"
                height="0.8rem"
              >
                {{ "N/A" }}
              </CButton>
            </td>
          </template>
          <template #IAM="{ item }">
            <show-score
              :score="getScore(item.IAM, 'IAM')"
              @show-hide="toggleRows('IAM')"
              :toggle="toggle.IAM"
            ></show-score>
          </template>
          <template #IAM-SCORE="{ item }">
            <show-data
              :data="item.IAM.Score"
              :check="item.IAM.Score === 0"
            ></show-data>
          </template>
          <template #IAM-USER="{ item }">
            <show-data
              :data="item.IAM.User"
              :FormatValueExceptions="true"
              :check="item.IAM.User.Value === 0"
            ></show-data>
          </template>
          <template #IAM-AGE="{ item }">
            <show-data
              :data="item.IAM.Age"
              :FormatValueExceptions="false"
              :check="item.IAM.Age === 0"
            ></show-data>
          </template>
          <template #IAM-ADMIN="{ item }">
            <show-data
              :data="item.IAM.Admin"
              :FormatValueExceptions="false"
              :check="item.IAM.Admin === 0"
            ></show-data>
          </template>
          <template #IAM-SVCCONSOLE="{ item }">
            <show-data
              :data="item.IAM.SvcConsole"
              :FormatValueExceptions="true"
              :check="item.IAM.SvcConsole.Value === 0"
            ></show-data>
          </template>
          <template #IAM-NOBOUNDARY="{ item }">
            <show-data
              :data="item.IAM.NoBoundary"
              :FormatValueExceptions="true"
              :check="item.IAM.NoBoundary.Value === 0"
            ></show-data>
          </template>
          <template #S3="{ item }">
            <show-score
              :score="getScore(item.S3, 'S3-ACCOUNT')"
              @show-hide="toggleRows('S3')"
              :toggle="toggle.S3"
            ></show-score>
          </template>
          <template #S3-Total="{ item }">
            <show-data
              :data="item.S3.Total"
              :FormatValueExceptions="false"
              :check="item.S3.Total"
              :score="getScore(item.S3, 'S3-ACCOUNT')"
              isTotal
            ></show-data
          ></template>
          <template #S3-UnEncrypted="{ item }">
            <show-data
              :data="item.S3.UnEncrypted"
              :FormatValueExceptions="true"
              :check="item.S3.UnEncrypted.Value === 0"
              :score="getScore(item.S3, 'S3-ACCOUNT')"
            ></show-data>
          </template>
          <template #S3-EncryptionInTransit="{ item }">
            <show-data
              :data="item.S3.EncryptionInTransit"
              :FormatValueExceptions="true"
              :check="item.S3.EncryptionInTransit.Value === 0"
              :score="getScore(item.S3, 'S3-ACCOUNT')"
            ></show-data>
          </template>
          <template #S3-Public="{ item }">
            <show-data
              :data="item.S3.Public"
              :FormatValueExceptions="true"
              :check="item.S3.Public.Value === 0"
              :score="getScore(item.S3, 'S3-ACCOUNT')"
            ></show-data>
          </template>
          <template #S3-CanBePublic="{ item }">
            <show-data
              :data="item.S3.CanBePublic"
              :FormatValueExceptions="true"
              :check="item.S3.CanBePublic.Value === 0"
              :score="getScore(item.S3, 'S3-ACCOUNT')"
            ></show-data>
          </template>
          <template #S3-AccessPoint-Total="{ item }">
            <show-data
              :data="item.S3.AccessPoint.Total"
              :FormatValueExceptions="false"
              isTotal
              :check="item.S3.AccessPoint.Total.Value === 0"
              :score="getScore(item.S3, 'S3-ACCOUNT')"
            ></show-data>
          </template>
          <template #S3-AccessPoint-Public="{ item }">
            <show-data
              :data="item.S3.AccessPoint.Public"
              :FormatValueExceptions="true"
              :check="item.S3.AccessPoint.Public.Value === 0"
              :score="getScore(item.S3, 'S3-ACCOUNT')"
            ></show-data>
          </template>
          <template #S3-AccessPoint-CanBePublic="{ item }">
            <show-data
              :data="item.S3.AccessPoint.CanBePublic"
              :FormatValueExceptions="true"
              :check="item.S3.AccessPoint.CanBePublic.Value === 0"
              :score="getScore(item.S3, 'S3-ACCOUNT')"
            ></show-data>
          </template>
          <template #RDS="{ item }">
            <show-score
              :score="getScore(item.RDS, 'RDS-ACCOUNT')"
              @show-hide="toggleRows('RDS')"
              :toggle="toggle.RDS"
            ></show-score>
          </template>
          <template #RDS-Total="{ item }">
            <show-data
              :data="item.RDS.Total"
              :FormatValueExceptions="false"
              isTotal
              :check="item.RDS.Total"
              :score="getScore(item.RDS, 'RDS-ACCOUNT')"
            ></show-data
          ></template>
          <template #RDS-UnEncrypted="{ item }">
            <show-data
              :data="item.RDS.UnEncrypted"
              :FormatValueExceptions="true"
              :check="item.RDS.UnEncrypted.Value === 0"
              :score="getScore(item.RDS, 'RDS-ACCOUNT')"
            ></show-data>
          </template>
          <template #RDS-Public="{ item }">
            <show-data
              :data="item.RDS.Public"
              :FormatValueExceptions="true"
              :check="item.RDS.Public.Value === 0"
              :score="getScore(item.RDS, 'RDS-ACCOUNT')"
            ></show-data>
          </template>
          <template #EBS="{ item }">
            <show-score
              :score="
                item.EBS.Total === 0
                  ? 'N/A'
                  : getScore(item.EBS.UnEncrypted, 'ONE', 0)
              "
              @show-hide="toggleRows('EBS')"
              :toggle="toggle.EBS"
            ></show-score>
          </template>
          <template #EBS-Total="{ item }">
            <show-data
              :data="item.EBS.Total"
              :FormatValueExceptions="false"
              :check="item.EBS.Total"
              isTotal
              :score="
                item.EBS.Total === 0
                  ? 'N/A'
                  : getScore(item.EBS.UnEncrypted, 'ONE', 0)
              "
            ></show-data
          ></template>
          <template #EBS-UnEncrypted="{ item }">
            <show-data
              :data="item.EBS.UnEncrypted"
              :FormatValueExceptions="true"
              :check="item.EBS.UnEncrypted.Value === 0"
              :score="
                item.EBS.Total === 0
                  ? 'N/A'
                  : getScore(item.EBS.UnEncrypted, 'ONE', 0)
              "
            ></show-data>
          </template>
          <template #EFS="{ item }">
            <show-score
              :score="
                item.EFS.Total === 0
                  ? 'N/A'
                  : getScore(item.EFS.UnEncrypted, 'ONE', 0)
              "
              @show-hide="toggleRows('EFS')"
              :toggle="toggle.EFS"
            ></show-score>
          </template>
          <template #EFS-Total="{ item }">
            <show-data
              :data="item.EFS.Total"
              :FormatValueExceptions="false"
              :check="item.EFS.Total"
              isTotal
              :score="
                item.EFS.Total === 0
                  ? 'N/A'
                  : getScore(item.EFS.UnEncrypted, 'ONE', 0)
              "
            ></show-data
          ></template>
          <template #EFS-UnEncrypted="{ item }">
            <show-data
              :data="item.EFS.UnEncrypted"
              :FormatValueExceptions="true"
              :check="item.EFS.UnEncrypted.Value === 0"
              :score="
                item.EFS.Total === 0
                  ? 'N/A'
                  : getScore(item.EFS.UnEncrypted, 'ONE', 0)
              "
            ></show-data>
          </template>
          <template #FSX="{ item }">
            <show-score
              :score="
                item.FSX.Total === 0
                  ? 'N/A'
                  : getScore(item.FSX.UnEncrypted, 'ONE', 0)
              "
              @show-hide="toggleRows('FSX')"
              :toggle="toggle.FSX"
            ></show-score>
          </template>
          <template #FSX-Total="{ item }">
            <show-data
              :data="item.FSX.Total"
              :FormatValueExceptions="false"
              :check="item.FSX.Total"
              isTotal
              :score="
                item.FSX.Total === 0
                  ? 'N/A'
                  : getScore(item.FSX.UnEncrypted, 'ONE', 0)
              "
            ></show-data
          ></template>
          <template #FSX-UnEncrypted="{ item }">
            <show-data
              :data="item.FSX.UnEncrypted"
              :FormatValueExceptions="true"
              :check="item.FSX.UnEncrypted.Value === 0"
              :score="
                item.FSX.Total === 0
                  ? 'N/A'
                  : getScore(item.FSX.UnEncrypted, 'ONE', 0)
              "
            ></show-data>
          </template>
          <template #NatGateway="{ item }">
            <show-score
              :score="getScore(item.NatGateway, 'NatGateway-Account', 0)"
              @show-hide="toggleRows('NatGateway')"
              :toggle="toggle.NatGateway"
            ></show-score>
          </template>
          <template #NatGateway-Total="{ item }">
            <show-data
              :data="item.NatGateway.Total"
              :FormatValueExceptions="true"
              :check="item.NatGateway.Total.Value === 0"
              isTotal
            ></show-data
          ></template>
          <template #NatGateway-NETWORKFIREWALL-NotAttached="{ item }">
            <show-data
              :data="item.NatGateway.NETWORKFIREWALL.NotAttached"
              :FormatValueExceptions="true"
              :check="item.NatGateway.NETWORKFIREWALL.NotAttached.Value === 0"
            ></show-data
          ></template>
          <template #NatGateway-NETWORKFIREWALL-BadNetworkFirewall="{ item }">
            <show-data
              :data="item.NatGateway.NETWORKFIREWALL.BadNetworkFirewall"
              :FormatValueExceptions="true"
              :check="
                item.NatGateway.NETWORKFIREWALL.BadNetworkFirewall.Value === 0
              "
            ></show-data
          ></template>
          <template #EC2="{ item }">
            <show-score
              :score="getScore(item.EC2, 'EC2-Account', 0)"
              @show-hide="toggleRows('EC2')"
              :toggle="toggle.EC2"
            ></show-score>
          </template>
          <template #EC2-Total="{ item }">
            <show-data
              :data="item.EC2.Total"
              :FormatValueExceptions="false"
              :check="item.EC2.Total"
              isTotal
              :score="getScore(item.EC2, 'EC2-Account', 0)"
            ></show-data
          ></template>
          <template #EC2-SSHRDP_Public="{ item }">
            <show-data
              :data="item.EC2.SSHRDP_Public"
              :FormatValueExceptions="true"
              :check="item.EC2.SSHRDP_Public.Value === 0"
              :score="getScore(item.EC2, 'EC2-Account', 0)"
            ></show-data>
          </template>
          <template #EC2-SSHRDP_Private="{ item }">
            <show-data
              :data="item.EC2.SSHRDP_Private"
              :FormatValueExceptions="true"
              isTotal
            ></show-data>
          </template>
          <template #EC2-UNWANTEDPORTS_Public="{ item }">
            <show-data
              :data="item.EC2.UNWANTEDPORTS_Public"
              :FormatValueExceptions="true"
              :check="item.EC2.UNWANTEDPORTS_Public.Value === 0"
              :score="getScore(item.EC2, 'EC2-Account', 0)"
            ></show-data>
          </template>
          <template #EC2-UNWANTEDPORTS_Private="{ item }">
            <show-data
              :data="item.EC2.UNWANTEDPORTS_Private"
              :FormatValueExceptions="true"
              isTotal
            ></show-data>
          </template>
          <template #EC2-SG00_Public="{ item }">
            <show-data
              :data="item.EC2.SG00_Public"
              :FormatValueExceptions="true"
              :check="item.EC2.SG00_Public.Value === 0"
              :score="getScore(item.EC2, 'EC2-Account', 0)"
            ></show-data>
          </template>
          <template #EC2-SG00_Private="{ item }">
            <show-data
              :data="item.EC2.SG00_Private"
              :FormatValueExceptions="true"
              isTotal
            ></show-data>
          </template>
          <template #EC2-AutoscaledVMs="{ item }">
            <show-data
              :data="item.EC2.AutoscaledVMs"
              :FormatValueExceptions="false"
              isTotal
            ></show-data>
          </template>
          <template #EC2-Public="{ item }">
            <show-data
              :data="item.EC2.Public"
              :FormatValueExceptions="true"
              :check="item.EC2.Public.Value === 0"
              :score="getScore(item.EC2, 'EC2-Account', 0)"
            ></show-data>
          </template>
          <template #EC2-Metadata-IMDSv2NotEnforced="{ item }">
            <show-data
              :data="item.EC2.Metadata.IMDSv2NotEnforced"
              :FormatValueExceptions="true"
              :check="item.EC2.Metadata.IMDSv2NotEnforced.Value === 0"
              :score="getScore(item.EC2, 'EC2-Account', 0)"
            ></show-data>
          </template>
          <template #EC2-SSMAgent="{ item }">
            <show-data
              :data="item.EC2.SSMAgent"
              :FormatValueExceptions="true"
              :check="item.EC2.SSMAgent.Value === 0"
              :score="getScore(item.EC2, 'EC2-Account', 0)"
            ></show-data>
          </template>
          <template #EC2-SSMAgent-NotInstalled="{ item }">
            <show-data
              :data="item.EC2.SSMAgent.NotInstalled"
              :FormatValueExceptions="true"
              :check="item.EC2.SSMAgent.NotInstalled.Value === 0"
              :score="getScore(item.EC2, 'EC2-Account', 0)"
              isTotal
            ></show-data>
          </template>
          <template #EC2-SSMAgent-NotReachable="{ item }">
            <show-data
              :data="item.EC2.SSMAgent.NotReachable"
              :FormatValueExceptions="true"
              :check="item.EC2.SSMAgent.NotReachable.Value === 0"
              :score="getScore(item.EC2, 'EC2-Account', 0)"
              isTotal
            ></show-data>
          </template>
          <template #EC2-SSMAgent-NotUptodate="{ item }">
            <show-data
              :data="item.EC2.SSMAgent.NotUptodate"
              :FormatValueExceptions="true"
              :check="item.EC2.SSMAgent.NotUptodate.Value === 0"
              :score="getScore(item.EC2, 'EC2-Account', 0)"
              isTotal
            ></show-data>
          </template>
          <template #EC2-Permissions="{ item }">
            <show-data
              :data="item.EC2.Permissions"
              :FormatValueExceptions="true"
              :check="item.EC2.Permissions.Value === 0"
              :score="getScore(item.EC2, 'EC2-Account', 0)"
            ></show-data>
          </template>
          <template #EC2-Permissions-Admin="{ item }">
            <show-data
              :data="item.EC2.Permissions.Admin"
              :FormatValueExceptions="true"
              :check="item.EC2.Permissions.Admin.Value === 0"
              :score="getScore(item.EC2, 'EC2-Account', 0)"
              isTotal
            ></show-data>
          </template>
          <template #EC2-Permissions-PowerUser="{ item }">
            <show-data
              :data="item.EC2.Permissions.PowerUser"
              :FormatValueExceptions="true"
              :check="item.EC2.Permissions.PowerUser.Value === 0"
              :score="getScore(item.EC2, 'EC2-Account', 0)"
              isTotal
            ></show-data>
          </template>
          <template #EC2-InspectorV2-MissingScan="{ item }">
            <show-data
              :data="item.EC2.InspectorV2.MissingScan"
              :FormatValueExceptions="true"
              :check="item.EC2.InspectorV2.MissingScan.Value === 0"
              :score="getScore(item.EC2, 'EC2-Account', 0)"
              isTotal
            ></show-data>
          </template>
          <template #EC2-Inspector-UnsupportedRegion="{ item }">
            <show-data
              :data="item.EC2.Inspector.NeverRun.UnsupportedRegion"
              :FormatValueExceptions="false"
              :check="item.EC2.Inspector.NeverRun.UnsupportedRegion.Value === 0"
              :score="getScore(item.EC2, 'EC2-Account', 0)"
              isTotal
            ></show-data>
          </template>
          <template #EC2-Inspector-NeverRun="{ item }">
            <show-data
              :data="item.EC2.Inspector.NeverRun"
              :FormatValueExceptions="true"
              :check="item.EC2.Inspector.NeverRun.Value === 0"
              :score="getScore(item.EC2, 'EC2-Account', 0)"
              isTotal
            ></show-data>
          </template>
          <template #EC2-Inspector-TooOld="{ item }">
            <show-data
              :data="item.EC2.Inspector.TooOld"
              :FormatValueExceptions="true"
              :check="item.EC2.Inspector.TooOld.Value === 0"
              :score="getScore(item.EC2, 'EC2-Account', 0)"
              isTotal
            ></show-data>
          </template>
          <template #EC2-Inspector-Good="{ item }">
            <show-data
              :data="item.EC2.Inspector.Good"
              :FormatValueExceptions="true"
              :check="
                item.EC2.Inspector.Good.Value ===
                item.EC2.InspectorV2.MissingScan.Value
              "
              :score="getScore(item.EC2, 'EC2-Account', 0)"
              isTotal
            ></show-data>
          </template>
          <template #EC2-Inspector-GooodWithAgent="{ item }">
            <show-data
              :data="item.EC2.Inspector.GooodWithAgent"
              :FormatValueExceptions="true"
              :check="
                item.EC2.Inspector.GooodWithAgent.Value ===
                item.EC2.InspectorV2.MissingScan.Value
              "
              :score="getScore(item.EC2, 'EC2-Account', 0)"
            ></show-data>
          </template>
          <template #Inspector="{ item }">
            <show-score
              :score="getScore(item.Inspector, 'Inspector-Account', 0)"
              @show-hide="toggleRows('Inspector')"
              :toggle="toggle.Inspector"
            ></show-score>
          </template>
          <template #Inspector-Findings-Critical="{ item }">
            <show-data
              :data="item.Inspector.Findings.Critical"
              :FormatValueExceptions="false"
              :check="item.Inspector.Findings.Critical === 0"
              :score="getScore(item.Inspector, 'Inspector-Account', 0)"
            ></show-data>
          </template>
          <template #Inspector-Findings-High="{ item }">
            <show-data
              :data="item.Inspector.Findings.High"
              :FormatValueExceptions="false"
              :check="item.Inspector.Findings.High === 0"
              :score="getScore(item.Inspector, 'Inspector-Account', 0)"
            ></show-data>
          </template>
          <template #XLB="{ item }">
            <show-score
              :score="
                item.XLB.Total === 0
                  ? 'N/A'
                  : getScore(item.XLB.UnEncrypted, 'ONE', 0)
              "
              @show-hide="toggleRows('XLB')"
              :toggle="toggle.XLB"
            ></show-score>
          </template>
          <template #XLB-Total="{ item }">
            <show-data
              :data="item.XLB.Total"
              :FormatValueExceptions="false"
              :check="item.XLB.Total"
              isTotal
              :score="
                item.XLB.Total === 0
                  ? 'N/A'
                  : getScore(item.XLB.UnEncrypted, 'ONE', 0)
              "
            ></show-data
          ></template>
          <template #XLB-UnEncrypted="{ item }">
            <show-data
              :data="item.XLB.UnEncrypted"
              :FormatValueExceptions="true"
              :check="item.XLB.UnEncrypted.Value === 0"
              :score="
                item.XLB.Total === 0
                  ? 'N/A'
                  : getScore(item.XLB.UnEncrypted, 'ONE', 0)
              "
            ></show-data>
          </template>
          <template #Subnet="{ item }">
            <show-score
              :score="getScore(item.Subnet, 'Subnet-Account', 0)"
              :toggle="toggle.Subnet"
              @show-hide="toggleRows('Subnet')"
            ></show-score>
          </template>
          <template #Subnet-Total="{ item }">
            <show-data
              :data="item.Subnet.Total"
              isTotal
              :FormatValueExceptions="false"
              :check="item.Subnet.Total"
              :score="getScore(item.Subnet, 'Subnet-Account', 0)"
            ></show-data
          ></template>
          <template #Subnet-DefaultNACL="{ item }">
            <show-data
              :data="item.Subnet.DefaultNACL"
              :FormatValueExceptions="true"
              :check="item.Subnet.DefaultNACL.Value === 0"
              :score="getScore(item.Subnet, 'Subnet-Account', 0)"
            ></show-data>
          </template>
          <template #Subnet-InternetFacing="{ item }">
            <show-data
              :data="item.Subnet.InternetFacing"
              :FormatValueExceptions="true"
              :check="item.Subnet.InternetFacing.Value === 0"
              :score="getScore(item.Subnet, 'Subnet-Account', 0)"
            ></show-data>
          </template>
          <template #Subnet-WithOpenPortsNotBehindSG="{ item }">
            <show-data
              :data="item.Subnet.WithOpenPortsNotBehindSG"
              :FormatValueExceptions="true"
              :check="item.Subnet.WithOpenPortsNotBehindSG.Value === 0"
              :score="getScore(item.Subnet, 'Subnet-Account', 0)"
              isTotal
            ></show-data>
          </template>
          <template #Subnet-Public-WithOpenPortsNotBehindSG="{ item }">
            <show-data
              :data="item.Subnet.Public.WithOpenPortsNotBehindSG"
              :FormatValueExceptions="true"
              :check="item.Subnet.Public.WithOpenPortsNotBehindSG.Value === 0"
              :score="getScore(item.Subnet, 'Subnet-Account', 0)"
            ></show-data>
          </template>
          <template #Subnet-Public-Total="{ item }">
            <show-data
              :data="item.Subnet.Public.Total"
              :FormatValueExceptions="false"
              :check="item.Subnet.Public.Total"
              isTotal
              :score="getScore(item.Subnet, 'Subnet-Account', 0)"
            ></show-data
          ></template>
          <template #Subnet-Private-WithOpenPortsNotBehindSG="{ item }">
            <show-data
              :data="item.Subnet.Private.WithOpenPortsNotBehindSG"
              :FormatValueExceptions="true"
              :check="item.Subnet.Private.WithOpenPortsNotBehindSG.Value === 0"
              isTotal
              :score="getScore(item.Subnet, 'Subnet-Account', 0)"
            ></show-data>
          </template>
          <template #Subnet-Private-Total="{ item }">
            <show-data
              :data="item.Subnet.Private.Total"
              :FormatValueExceptions="false"
              :check="item.Subnet.Private.Total"
              isTotal
              :score="getScore(item.Subnet, 'Subnet-Account', 0)"
            ></show-data
          ></template>
          <template #CloudFront="{ item }">
            <show-score
              :score="getScore(item.CloudFront, 'CloudFront-ACCOUNT', 0)"
              @show-hide="toggleRows('CloudFront')"
              :toggle="toggle.CloudFront"
            ></show-score>
          </template>
          <template #CloudFront-Total="{ item }">
            <show-data
              :data="item.CloudFront.Total"
              :FormatValueExceptions="false"
              :check="item.CloudFront.Total"
              isTotal
              :score="getScore(item.CloudFront, 'CloudFront-ACCOUNT', 0)"
            ></show-data
          ></template>
          <template #CloudFront-TLS="{ item }">
            <show-data
              :data="item.CloudFront.TLS"
              :FormatValueExceptions="true"
              :check="item.CloudFront.TLS.Value === 0"
              :score="getScore(item.CloudFront, 'CloudFront-ACCOUNT', 0)"
            ></show-data
          ></template>
          <template #CloudFront-WithWAFv2="{ item }">
            <show-data
              :data="item.CloudFront.WithWAFv2"
              :FormatValueExceptions="true"
              isTotal
            ></show-data
          ></template>
          <template #CloudFront-WithWAFv2Valid="{ item }">
            <show-data
              :data="item.CloudFront.WithWAFv2Valid"
              :FormatValueExceptions="true"
              isTotal
            ></show-data
          ></template>
          <template #CloudFront-WithWAFv2Counting="{ item }">
            <show-data
              :data="item.CloudFront.WithWAFv2Counting"
              :FormatValueExceptions="true"
              isTotal
            ></show-data
          ></template>
          <template #CloudFront-WAFAnyVersionValid="{ item }">
            <show-data
              :data="item.CloudFront.WAFAnyVersionValid"
              :FormatValueExceptions="true"
              :check="
                item.CloudFront.WAFAnyVersionValid.Value +
                  item.CloudFront.WAFAnyVersionValid.Exceptions ===
                item.CloudFront.Total
              "
            ></show-data
          ></template>
          <template #PubResource="{ item }">
            <show-score
              :score="getScore(item.PubResource, 'PubResource-ACCOUNT', 0)"
              @show-hide="toggleRows('PubResource')"
              :toggle="toggle.PubResource"
            ></show-score>
          </template>
          <template #PubResource-Total="{ item }">
            <show-data
              :data="item.PubResource.Total"
              :FormatValueExceptions="false"
              :check="item.PubResource.Total"
              isTotal
              :score="getScore(item.PubResource, 'PubResource-ACCOUNT', 0)"
            ></show-data
          ></template>
          <template #PubResource-ShieldAndDRT="{ item }">
            <show-data
              :data="item.PubResource.ShieldAndDRT"
              :FormatValueExceptions="true"
              :check="
                item.PubResource.ShieldAndDRT.Value +
                  item.PubResource.ShieldAndDRT.Exceptions ===
                item.PubResource.Total
              "
              :score="getScore(item.PubResource, 'PubResource-ACCOUNT', 0)"
            ></show-data>
          </template>
          <template #PubResource-Shield="{ item }">
            <show-data
              :data="item.PubResource.Shield"
              :FormatValueExceptions="true"
              isTotal
            ></show-data>
          </template>
          <template #PubResource-NoShield="{ item }">
            <show-data
              :data="item.PubResource.NoShield"
              :FormatValueExceptions="true"
              :check="item.PubResource.NoShield.Value === 0"
              :score="getScore(item.PubResource, 'ONE', 0)"
              isTotal
            ></show-data>
          </template>
          <template #PubResource-InternetFacing="{ item }">
            <show-data
              :data="item.PubResource.InternetFacing"
              :FormatValueExceptions="true"
              :check="item.PubResource.InternetFacing.Value === 0"
              :score="getScore(item.PubResource, 'ONE', 0)"
            ></show-data>
          </template>
          <template #PubResource-ShieldTotal="{ item }">
            <show-data
              :data="item.PubResource.ShieldTotal"
              :FormatValueExceptions="false"
              :check="item.PubResource.ShieldTotal.Value"
              isTotal
              :score="getScore(item.PubResource, 'ONE', 0)"
            ></show-data
          ></template>
          <template #PubAppAndAPI="{ item }">
            <show-score
              :score="getScore(item.PubAppAndAPI, 'PubAppAndAPI-ACCOUNT', 0)"
              @show-hide="toggleRows('PubAppAndAPI')"
              :toggle="toggle.PubAppAndAPI"
            ></show-score>
          </template>
          <template #PubAppAndAPI-Total="{ item }">
            <show-data
              :data="item.PubAppAndAPI.Total"
              :FormatValueExceptions="false"
              :check="item.PubAppAndAPI.Total"
              isTotal
              :score="getScore(item.PubAppAndAPI, 'PubAppAndAPI-ACCOUNT', 0)"
            ></show-data
          ></template>
          <template #PubAppAndAPI-TLS="{ item }">
            <show-data
              :data="item.PubAppAndAPI.TLS"
              :FormatValueExceptions="true"
              :check="item.PubAppAndAPI.TLS.Value === 0"
              :score="getScore(item.PubAppAndAPI, 'PubAppAndAPI-ACCOUNT', 0)"
            ></show-data
          ></template>
          <template #PubAppAndAPI-NoWAFv2="{ item }">
            <show-data
              :data="item.PubAppAndAPI.NoWAFv2"
              :FormatValueExceptions="true"
              isTotal
            ></show-data
          ></template>
          <template #PubAppAndAPI-WAFv2="{ item }">
            <show-data
              :data="item.PubAppAndAPI.WAFv2"
              :FormatValueExceptions="true"
              isTotal
            ></show-data
          ></template>
          <template #PubAppAndAPI-WAFv2Valid="{ item }">
            <show-data
              :data="item.PubAppAndAPI.WAFv2Valid"
              :FormatValueExceptions="true"
              isTotal
            ></show-data
          ></template>
          <template #PubAppAndAPI-WAFv2Counting="{ item }">
            <show-data
              :data="item.PubAppAndAPI.WAFv2Counting"
              :FormatValueExceptions="true"
              isTotal
            ></show-data
          ></template>
          <template #PubAppAndAPI-WAFAnyVersionValid="{ item }">
            <show-data
              :data="item.PubAppAndAPI.WAFAnyVersionValid"
              :FormatValueExceptions="true"
              :check="
                item.PubAppAndAPI.WAFAnyVersionValid.Value +
                  item.PubAppAndAPI.WAFAnyVersionValid.Exceptions ===
                item.PubAppAndAPI.Total
              "
              :score="getScore(item.PubAppAndAPI, 'PubAppAndAPI-ACCOUNT', 0)"
            ></show-data
          ></template>
          <template #DYNAMODB="{ item }">
            <show-score
              :score="
                item.DYNAMODB.Total === 0
                  ? 'N/A'
                  : getScore(item.DYNAMODB.NoEncryption, 'ONE', 0)
              "
              @show-hide="toggleRows('DYNAMODB')"
              :toggle="toggle.DYNAMODB"
            ></show-score>
          </template>
          <template #DYNAMODB-Total="{ item }">
            <show-data
              :data="item.DYNAMODB.Total"
              :FormatValueExceptions="false"
              isTotal
              :check="item.DYNAMODB.Total"
              :score="
                item.DYNAMODB.Total === 0
                  ? 'N/A'
                  : getScore(item.DYNAMODB.NoEncryption, 'ONE', 0)
              "
            ></show-data
          ></template>
          <template #DYNAMODB-NoEncryption="{ item }">
            <show-data
              :data="item.DYNAMODB.NoEncryption"
              :FormatValueExceptions="true"
              :check="item.DYNAMODB.NoEncryption.Value === 0"
              :score="
                item.DYNAMODB.Total === 0
                  ? 'N/A'
                  : getScore(item.DYNAMODB.NoEncryption, 'ONE', 0)
              "
            ></show-data>
          </template>
          <template #ELASTIC_SEARCH="{ item }">
            <show-score
              :score="
                getScore(item.ELASTIC_SEARCH, 'ELASTIC_SEARCH-ACCOUNT', 0)
              "
              :toggle="toggle.ELASTIC_SEARCH"
              @show-hide="toggleRows('ELASTIC_SEARCH')"
            ></show-score>
          </template>
          <template #ELASTIC_SEARCH-Total="{ item }">
            <show-data
              :data="item.ELASTIC_SEARCH.Total"
              :FormatValueExceptions="false"
              :check="item.ELASTIC_SEARCH.Total"
              isTotal
              :score="
                getScore(item.ELASTIC_SEARCH, 'ELASTIC_SEARCH-ACCOUNT', 0)
              "
            ></show-data
          ></template>
          <template #ELASTIC_SEARCH-BadTLS="{ item }">
            <show-data
              :data="item.ELASTIC_SEARCH.BadTLS"
              :FormatValueExceptions="true"
              :check="item.ELASTIC_SEARCH.BadTLS.Value === 0"
              :score="
                getScore(item.ELASTIC_SEARCH, 'ELASTIC_SEARCH-ACCOUNT', 0)
              "
            ></show-data>
          </template>
          <template #ELASTIC_SEARCH-NoEncryptionAtRest="{ item }">
            <show-data
              :data="item.ELASTIC_SEARCH.NoEncryptionAtRest"
              :FormatValueExceptions="true"
              :check="item.ELASTIC_SEARCH.NoEncryptionAtRest.Value === 0"
              :score="
                getScore(item.ELASTIC_SEARCH, 'ELASTIC_SEARCH-ACCOUNT', 0)
              "
            ></show-data>
          </template>
          <template #ELASTIC_SEARCH-NoEncryptionInTransit="{ item }">
            <show-data
              :data="item.ELASTIC_SEARCH.NoEncryptionInTransit"
              :FormatValueExceptions="true"
              :check="item.ELASTIC_SEARCH.NoEncryptionInTransit.Value === 0"
              :score="
                getScore(item.ELASTIC_SEARCH, 'ELASTIC_SEARCH-ACCOUNT', 0)
              "
            ></show-data>
          </template>
          <template #ELASTIC_SEARCH-NoNodeToNodeEncryption="{ item }">
            <show-data
              :data="item.ELASTIC_SEARCH.NoNodeToNodeEncryption"
              :FormatValueExceptions="true"
              :check="item.ELASTIC_SEARCH.NoNodeToNodeEncryption.Value === 0"
              :score="
                getScore(item.ELASTIC_SEARCH, 'ELASTIC_SEARCH-ACCOUNT', 0)
              "
            ></show-data>
          </template>
          <template #ELASTICACHE="{ item }">
            <show-score
              :score="getScore(item.ELASTICACHE, 'ELASTICACHE-ACCOUNT', 0)"
              :toggle="toggle.ELASTICACHE"
              @show-hide="toggleRows('ELASTICACHE')"
            ></show-score>
          </template>
          <template #ELASTICACHE-Total="{ item }">
            <show-data
              :data="item.ELASTICACHE.Total"
              :FormatValueExceptions="false"
              :check="item.ELASTICACHE.Total"
              isTotal
              :score="getScore(item.ELASTICACHE, 'ELASTICACHE-ACCOUNT', 0)"
            ></show-data
          ></template>
          <template #ELASTICACHE-NoEncryptionAtRest="{ item }">
            <show-data
              :data="item.ELASTICACHE.NoEncryptionAtRest"
              :FormatValueExceptions="true"
              :check="item.ELASTICACHE.NoEncryptionAtRest.Value === 0"
              :score="getScore(item.ELASTICACHE, 'ELASTICACHE-ACCOUNT', 0)"
            ></show-data>
          </template>
          <template #ELASTICACHE-NoEncryptionInTransit="{ item }">
            <show-data
              :data="item.ELASTICACHE.NoEncryptionInTransit"
              :FormatValueExceptions="true"
              :check="item.ELASTICACHE.NoEncryptionInTransit.Value === 0"
              :score="getScore(item.ELASTICACHE, 'ELASTICACHE-ACCOUNT', 0)"
            ></show-data>
          </template>
          <template #KINESIS="{ item }">
            <show-score
              :score="
                item.KINESIS.Total === 0
                  ? 'N/A'
                  : getScore(item.KINESIS.NoEncryptionAtRest, 'ONE', 0)
              "
              @show-hide="toggleRows('KINESIS')"
              :toggle="toggle.KINESIS"
            ></show-score>
          </template>
          <template #KINESIS-Total="{ item }">
            <show-data
              :data="item.KINESIS.Total"
              :FormatValueExceptions="false"
              :check="item.KINESIS.Total"
              isTotal
              :score="
                item.KINESIS.Total === 0
                  ? 'N/A'
                  : getScore(item.KINESIS.NoEncryptionAtRest, 'ONE', 0)
              "
            ></show-data
          ></template>
          <template #KINESIS-NoEncryptionAtRest="{ item }">
            <show-data
              :data="item.KINESIS.NoEncryptionAtRest"
              :FormatValueExceptions="true"
              :check="item.KINESIS.NoEncryptionAtRest.Value === 0"
              :score="
                item.KINESIS.Total === 0
                  ? 'N/A'
                  : getScore(item.KINESIS.NoEncryptionAtRest, 'ONE', 0)
              "
            ></show-data>
          </template>
          <template #KINESISFIREHOSE="{ item }">
            <show-score
              :score="
                item.KINESISFIREHOSE.Total === 0
                  ? 'N/A'
                  : getScore(item.KINESISFIREHOSE.NoEncryptionAtRest, 'ONE', 0)
              "
              @show-hide="toggleRows('KINESISFIREHOSE')"
              :toggle="toggle.KINESISFIREHOSE"
            ></show-score>
          </template>
          <template #KINESISFIREHOSE-Total="{ item }">
            <show-data
              :data="item.KINESISFIREHOSE.Total"
              :FormatValueExceptions="false"
              :check="item.KINESISFIREHOSE.Total.Value"
              isTotal
              :score="
                item.KINESISFIREHOSE.Total === 0
                  ? 'N/A'
                  : getScore(item.KINESISFIREHOSE.NoEncryptionAtRest, 'ONE', 0)
              "
            ></show-data
          ></template>
          <template #KINESISFIREHOSE-NoEncryptionAtRest="{ item }">
            <show-data
              :data="item.KINESISFIREHOSE.NoEncryptionAtRest"
              :FormatValueExceptions="true"
              :check="item.KINESISFIREHOSE.NoEncryptionAtRest.Value === 0"
              :score="
                item.KINESISFIREHOSE.Total === 0
                  ? 'N/A'
                  : getScore(item.KINESISFIREHOSE.NoEncryptionAtRest, 'ONE', 0)
              "
            ></show-data>
          </template>
          <template #SQS="{ item }">
            <show-score
              :score="
                item.SQS.Total === 0
                  ? 'N/A'
                  : getScore(item.SQS.NoEncryptionAtRest, 'ONE', 0)
              "
              @show-hide="toggleRows('SQS')"
              :toggle="toggle.SQS"
            ></show-score>
          </template>
          <template #SQS-Total="{ item }">
            <show-data
              :data="item.SQS.Total"
              :FormatValueExceptions="false"
              :check="item.SQS.Total"
              isTotal
              :score="
                item.SQS.Total === 0
                  ? 'N/A'
                  : getScore(item.SQS.NoEncryptionAtRest, 'ONE', 0)
              "
            ></show-data
          ></template>
          <template #SQS-NoEncryptionAtRest="{ item }">
            <show-data
              :data="item.SQS.NoEncryptionAtRest"
              :FormatValueExceptions="true"
              :check="item.SQS.NoEncryptionAtRest.Value === 0"
              :score="
                item.SQS.Total === 0
                  ? 'N/A'
                  : getScore(item.SQS.NoEncryptionAtRest, 'ONE', 0)
              "
            ></show-data>
          </template>
          <template #BACKUP="{ item }">
            <show-score
              :score="
                item.BACKUP.Total === 0
                  ? 'N/A'
                  : getScore(item.BACKUP.NoEncryptionAtRest, 'ONE', 0)
              "
              @show-hide="toggleRows('BACKUP')"
              :toggle="toggle.BACKUP"
            ></show-score>
          </template>
          <template #BACKUP-Total="{ item }">
            <show-data
              :data="item.BACKUP.Total"
              :FormatValueExceptions="false"
              :check="item.BACKUP.Total"
              isTotal
              :score="
                item.BACKUP.Total === 0
                  ? 'N/A'
                  : getScore(item.BACKUP.NoEncryptionAtRest, 'ONE', 0)
              "
            ></show-data
          ></template>
          <template #BACKUP-NoEncryptionAtRest="{ item }">
            <show-data
              :data="item.BACKUP.NoEncryptionAtRest"
              :FormatValueExceptions="true"
              :check="item.BACKUP.NoEncryptionAtRest.Value === 0"
              :score="
                item.BACKUP.Total === 0
                  ? 'N/A'
                  : getScore(item.BACKUP.NoEncryptionAtRest, 'ONE', 0)
              "
            ></show-data>
          </template>
          <template #XRAY="{ item }">
            <show-score
              :score="
                item.XRAY.Total === 0
                  ? 'N/A'
                  : getScore(item.XRAY.NoEncryption, 'ONE', 0)
              "
              @show-hide="toggleRows('XRAY')"
              :toggle="toggle.XRAY"
            ></show-score>
          </template>
          <template #XRAY-Total="{ item }">
            <show-data
              :data="item.XRAY.Total"
              :FormatValueExceptions="false"
              :check="item.XRAY.Total"
              isTotal
              :score="
                item.XRAY.Total === 0
                  ? 'N/A'
                  : getScore(item.XRAY.NoEncryption, 'ONE', 0)
              "
            ></show-data
          ></template>
          <template #XRAY-NoEncryption="{ item }">
            <show-data
              :data="item.XRAY.NoEncryption"
              :FormatValueExceptions="true"
              :check="item.XRAY.NoEncryption.Value === 0"
              :score="
                item.XRAY.Total === 0
                  ? 'N/A'
                  : getScore(item.XRAY.NoEncryption, 'ONE', 0)
              "
            ></show-data>
          </template>
          <template #REDSHIFT="{ item }">
            <show-score
              :score="getScore(item.REDSHIFT, 'REDSHIFT-ACCOUNT')"
              @show-hide="toggleRows('REDSHIFT')"
              :toggle="toggle.REDSHIFT"
            ></show-score>
          </template>
          <template #REDSHIFT-Total="{ item }">
            <show-data
              :data="item.REDSHIFT.Total"
              :FormatValueExceptions="false"
              :check="item.REDSHIFT.Total"
              isTotal
              :score="getScore(item.REDSHIFT, 'REDSHIFT-ACCOUNT')"
            ></show-data
          ></template>
          <template #REDSHIFT-NoEncryptionAtRest="{ item }">
            <show-data
              :data="item.REDSHIFT.NoEncryptionAtRest"
              :FormatValueExceptions="true"
              :check="item.REDSHIFT.NoEncryptionAtRest.Value === 0"
              :score="getScore(item.REDSHIFT, 'REDSHIFT-ACCOUNT')"
            ></show-data>
          </template>
          <template #REDSHIFT-Public="{ item }">
            <show-data
              :data="item.REDSHIFT.Public"
              :FormatValueExceptions="true"
              :check="item.REDSHIFT.Public.Value === 0"
              :score="getScore(item.REDSHIFT, 'REDSHIFT-ACCOUNT')"
            ></show-data>
          </template>
          <template #SAGEMAKER_NOTEBOOKINSTANCE="{ item }">
            <show-score
              :score="
                getScore(
                  item.SAGEMAKER_NOTEBOOKINSTANCE,
                  'SAGEMAKER_NOTEBOOKINSTANCE-Account',
                  100
                )
              "
              :toggle="toggle.SAGEMAKER_NOTEBOOKINSTANCE"
              @show-hide="toggleRows('SAGEMAKER_NOTEBOOKINSTANCE')"
            ></show-score>
          </template>
          <template #SAGEMAKER_NOTEBOOKINSTANCE-Total="{ item }">
            <show-data
              :data="item.SAGEMAKER_NOTEBOOKINSTANCE.Total"
              :FormatValueExceptions="false"
              :check="item.SAGEMAKER_NOTEBOOKINSTANCE.Total"
              isTotal
              :score="
                getScore(
                  item.SAGEMAKER_NOTEBOOKINSTANCE,
                  'SAGEMAKER_NOTEBOOKINSTANCE-Account',
                  100
                )
              "
            ></show-data
          ></template>
          <template #SAGEMAKER_NOTEBOOKINSTANCE-DirectInternetAccess="{ item }">
            <show-data
              :data="item.SAGEMAKER_NOTEBOOKINSTANCE.DirectInternetAccess"
              :FormatValueExceptions="true"
              :check="
                item.SAGEMAKER_NOTEBOOKINSTANCE.DirectInternetAccess.Value === 0
              "
            ></show-data>
          </template>
          <template #SAGEMAKER_NOTEBOOKINSTANCE-RootAccess="{ item }">
            <show-data
              :data="item.SAGEMAKER_NOTEBOOKINSTANCE.RootAccess"
              :FormatValueExceptions="true"
              :check="item.SAGEMAKER_NOTEBOOKINSTANCE.RootAccess.Value === 0"
              :score="
                getScore(
                  item.SAGEMAKER_NOTEBOOKINSTANCE,
                  'SAGEMAKER_NOTEBOOKINSTANCE-Account',
                  100
                )
              "
            ></show-data>
          </template>
          <template #SAGEMAKER_TRAININGJOB="{ item }">
            <show-score
              :score="
                getScore(
                  item.SAGEMAKER_TRAININGJOB,
                  'SAGEMAKER_TRAININGJOB-ACCOUNT',
                  0
                )
              "
              :toggle="toggle.SAGEMAKER_TRAININGJOB"
              @show-hide="toggleRows('SAGEMAKER_TRAININGJOB')"
            ></show-score>
          </template>
          <template #SAGEMAKER_TRAININGJOB-Total="{ item }">
            <show-data
              :data="item.SAGEMAKER_TRAININGJOB.Total"
              :FormatValueExceptions="false"
              :check="item.SAGEMAKER_TRAININGJOB.Total"
              isTotal
              :score="
                getScore(
                  item.SAGEMAKER_TRAININGJOB,
                  'SAGEMAKER_TRAININGJOB-ACCOUNT',
                  100
                )
              "
            ></show-data
          ></template>
          <template
            #SAGEMAKER_TRAININGJOB-NoEnableInterContainerTrafficEncryption="{
              item,
            }"
          >
            <show-data
              :data="
                item.SAGEMAKER_TRAININGJOB
                  .NoEnableInterContainerTrafficEncryption
              "
              :FormatValueExceptions="true"
              :score="
                getScore(
                  item.SAGEMAKER_TRAININGJOB,
                  'SAGEMAKER_TRAININGJOB-ACCOUNT',
                  0
                )
              "
              :check="
                item.SAGEMAKER_TRAININGJOB
                  .NoEnableInterContainerTrafficEncryption.Value === 0
              "
            ></show-data>
          </template>
          <template #SAGEMAKER_TRAININGJOB-NoNetworkIsolation="{ item }">
            <show-data
              :data="item.SAGEMAKER_TRAININGJOB.NoNetworkIsolation"
              :score="
                getScore(
                  item.SAGEMAKER_TRAININGJOB,
                  'SAGEMAKER_TRAININGJOB-ACCOUNT',
                  0
                )
              "
              :FormatValueExceptions="true"
              :check="item.SAGEMAKER_TRAININGJOB.NoNetworkIsolation.Value === 0"
            ></show-data>
          </template>
          <template #WORKSPACES="{ item }">
            <show-score
              :score="getScore(item.WORKSPACES, 'WORKSPACES-ACCOUNT')"
              @show-hide="toggleRows('WORKSPACES')"
              :toggle="toggle.WORKSPACES"
            ></show-score>
          </template>
          <template #WORKSPACES-Total="{ item }">
            <show-data
              :data="item.WORKSPACES.Total"
              :FormatValueExceptions="false"
              :check="item.WORKSPACES.Total"
              isTotal
              :score="getScore(item.WORKSPACES, 'WORKSPACES-ACCOUNT')"
            ></show-data
          ></template>
          <template #WORKSPACES-NoEncryptionAtRest="{ item }">
            <show-data
              :data="item.WORKSPACES.NoEncryptionAtRest"
              :FormatValueExceptions="true"
              :check="item.WORKSPACES.NoEncryptionAtRest.Value === 0"
              :score="getScore(item.WORKSPACES, 'WORKSPACES-ACCOUNT')"
            ></show-data>
          </template>
          <template #WORKSPACES-NoEncryptionRoot="{ item }">
            <show-data
              :data="item.WORKSPACES.NoEncryptionRoot"
              :FormatValueExceptions="true"
              :check="item.WORKSPACES.NoEncryptionRoot.Value === 0"
              :score="getScore(item.WORKSPACES, 'WORKSPACES-ACCOUNT')"
            ></show-data>
          </template>
          <template #WORKSPACES-NoEncryptionUser="{ item }">
            <show-data
              :data="item.WORKSPACES.NoEncryptionUser"
              :FormatValueExceptions="true"
              :check="item.WORKSPACES.NoEncryptionUser.Value === 0"
              :score="getScore(item.WORKSPACES, 'WORKSPACES-ACCOUNT')"
            ></show-data>
          </template>
          <template #WORKSPACES-Public="{ item }">
            <show-data
              :data="item.WORKSPACES.Public"
              :FormatValueExceptions="true"
              :check="item.WORKSPACES.Public.Value === 0"
              :score="getScore(item.WORKSPACES, 'WORKSPACES-ACCOUNT')"
            ></show-data>
          </template>
          <template #GLUE="{ item }">
            <show-score
              :score="getScore(item.GLUE, 'GLUE-ACCOUNT', 0)"
              :toggle="toggle.GLUE"
              @show-hide="toggleRows('GLUE')"
            ></show-score>
          </template>
          <template #GLUE-Total="{ item }">
            <show-data
              :data="item.GLUE.Total"
              :FormatValueExceptions="false"
              :check="item.GLUE.Total"
              isTotal
              :score="getScore(item.GLUE, 'GLUE-ACCOUNT', 0)"
            ></show-data
          ></template>
          <template #GLUE-NoEncryptionAtRest="{ item }">
            <show-data
              :data="item.GLUE.NoEncryptionAtRest"
              :FormatValueExceptions="true"
              :check="item.GLUE.NoEncryptionAtRest.Value === 0"
              :score="getScore(item.GLUE, 'GLUE-ACCOUNT', 0)"
            ></show-data>
          </template>
          <template #GLUE-NoEncryptionInTransit="{ item }">
            <show-data
              :data="item.GLUE.NoEncryptionInTransit"
              :FormatValueExceptions="true"
              :check="item.GLUE.NoEncryptionInTransit.Value === 0"
              :score="getScore(item.GLUE, 'GLUE-ACCOUNT', 0)"
            ></show-data>
          </template>
          <template #GLUE-NoConnectionPasswordEncryption="{ item }">
            <show-data
              :data="item.GLUE.NoConnectionPasswordEncryption"
              :FormatValueExceptions="true"
              :check="item.GLUE.NoConnectionPasswordEncryption.Value === 0"
              :score="getScore(item.GLUE, 'GLUE-ACCOUNT', 0)"
            ></show-data>
          </template>
          <template #STORAGEGATEWAY="{ item }">
            <show-score
              :score="
                getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY-ACCOUNT', 0)
              "
              :toggle="toggle.STORAGEGATEWAY"
              @show-hide="toggleRows('STORAGEGATEWAY')"
            ></show-score>
          </template>
          <template #STORAGEGATEWAY-Total="{ item }">
            <show-data
              :data="item.STORAGEGATEWAY.Total"
              :FormatValueExceptions="false"
              :check="item.STORAGEGATEWAY.Total"
              isTotal
              :score="
                getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY-ACCOUNT', 0)
              "
            ></show-data
          ></template>
          <template #STORAGEGATEWAY-VOL-NoEncryptionAtRest="{ item }">
            <show-data
              :data="
                item.STORAGEGATEWAY.STORAGEGATEWAYVOLUME.NoEncryptionAtRest
              "
              :FormatValueExceptions="true"
              :check="
                item.STORAGEGATEWAY.STORAGEGATEWAYVOLUME.NoEncryptionAtRest
                  .Value === 0
              "
              :score="
                getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY-ACCOUNT', 0)
              "
            ></show-data>
          </template>
          <template #STORAGEGATEWAY-VOL-Total="{ item }">
            <show-data
              :data="item.STORAGEGATEWAY.STORAGEGATEWAYVOLUME.Total"
              :FormatValueExceptions="false"
              :check="item.STORAGEGATEWAY.STORAGEGATEWAYVOLUME.Total"
              isTotal
              :score="
                getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY-ACCOUNT', 0)
              "
            ></show-data>
          </template>
          <template #STORAGEGATEWAY-TAPE-NoEncryptionAtRest="{ item }">
            <show-data
              :data="item.STORAGEGATEWAY.STORAGEGATEWAYTAPE.NoEncryptionAtRest"
              :FormatValueExceptions="true"
              :check="
                item.STORAGEGATEWAY.STORAGEGATEWAYTAPE.NoEncryptionAtRest
                  .Value === 0
              "
              :score="
                getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY-ACCOUNT', 0)
              "
            ></show-data>
          </template>
          <template #STORAGEGATEWAY-TAPE-Total="{ item }">
            <show-data
              :data="item.STORAGEGATEWAY.STORAGEGATEWAYTAPE.Total"
              :FormatValueExceptions="false"
              :check="item.STORAGEGATEWAY.STORAGEGATEWAYTAPE.Total"
              isTotal
              :score="
                getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY-ACCOUNT', 0)
              "
            ></show-data>
          </template>
          <template #STORAGEGATEWAY-SHARE-Total="{ item }">
            <show-data
              :data="item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE.Total"
              :FormatValueExceptions="false"
              :check="item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE.Total"
              isTotal
              :score="
                getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY-ACCOUNT', 0)
              "
            ></show-data>
          </template>
          <template #STORAGEGATEWAY-SHARE-NoEncryptionAtRest="{ item }">
            <show-data
              :data="
                item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE.NoEncryptionAtRest
              "
              :FormatValueExceptions="true"
              :check="
                item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE.NoEncryptionAtRest
                  .Value === 0
              "
              :score="
                getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY-ACCOUNT', 0)
              "
            ></show-data>
          </template>
          <template #STORAGEGATEWAY-SHARE-NoEncryptionInTransit="{ item }">
            <show-data
              :data="
                item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE
                  .NoEncryptionInTransit
              "
              :FormatValueExceptions="true"
              :check="
                item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE
                  .NoEncryptionInTransit.Value === 0
              "
              :score="
                getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY-ACCOUNT', 0)
              "
            ></show-data>
          </template>
          <template #STORAGEGATEWAY-SHARE-NoGuestPassword="{ item }">
            <show-data
              :data="
                item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE.NoGuestPassword
              "
              :FormatValueExceptions="true"
              :check="
                item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE.NoGuestPassword
                  .Value === 0
              "
              :score="
                getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY-ACCOUNT', 0)
              "
            ></show-data>
          </template>
          <template #VPC="{ item }">
            <show-score
              :score="getScore(item.VPC, 'VPC-Account', 0)"
              @show-hide="toggleRows('VPC')"
              :toggle="toggle.VPC"
            ></show-score>
          </template>
          <template #VPC-Total="{ item }">
            <show-data
              :data="item.VPC.Total"
              :FormatValueExceptions="false"
              :check="item.VPC.Total"
              isTotal
              :score="getScore(item.VPC, 'VPC-Account', 0)"
            ></show-data
          ></template>
          <template #VPC-Cleaning="{ item }">
            <show-data
              :data="item.VPC.Cleaning"
              :FormatValueExceptions="true"
              :check="item.VPC.Cleaning.Value === 0"
              :score="getScore(item.VPC, 'VPC-Account', 0)"
            ></show-data>
          </template>
          <template #VPC-NonCompliantIP="{ item }">
            <show-data
              :data="item.VPC.NonCompliantIP"
              :FormatValueExceptions="true"
              :check="item.VPC.NonCompliantIP.Value === 0"
              :score="getScore(item.VPC, 'VPC-Account', 0)"
            ></show-data>
          </template>
          <template #NETWORKFIREWALL="{ item }">
            <show-score
              :score="
                getScore(item.NETWORKFIREWALL, 'NETWORKFIREWALL-Account', 0)
              "
              @show-hide="toggleRows('NETWORKFIREWALL')"
              :toggle="toggle.NETWORKFIREWALL"
            ></show-score>
          </template>
          <template #NETWORKFIREWALL-Total="{ item }">
            <show-data
              :data="item.NETWORKFIREWALL.Total"
              :FormatValueExceptions="false"
              :check="item.NETWORKFIREWALL.Total"
              isTotal
              :score="
                getScore(item.NETWORKFIREWALL, 'NETWORKFIREWALL-Account', 0)
              "
            ></show-data
          ></template>
          <template #NETWORKFIREWALL-LoggingDisabled="{ item }">
            <show-data
              :data="item.NETWORKFIREWALL.LoggingDisabled"
              :FormatValueExceptions="true"
              :check="item.NETWORKFIREWALL.LoggingDisabled.Value === 0"
              :score="
                getScore(item.NETWORKFIREWALL, 'NETWORKFIREWALL-Account', 0)
              "
            ></show-data>
          </template>
          <template #NETWORKFIREWALL-AllowAnyAny="{ item }">
            <show-data
              :data="item.NETWORKFIREWALL.AllowAnyAny"
              :FormatValueExceptions="true"
              :check="item.NETWORKFIREWALL.AllowAnyAny.Value === 0"
              :score="
                getScore(item.NETWORKFIREWALL, 'NETWORKFIREWALL-Account', 0)
              "
            ></show-data>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { getBaseScore } from "./components/base/GetScore.js";
import ShowScore from "./components/base/ShowScore.vue";
import ShowData from "./components/base/ShowData.vue";
import { insertDetailsFields } from "./components/helpers.js";
import { defaultTips } from "./components/base/Tips.js";
import SecurityHelp from "./SecurityHelp.vue";
import {
  AccountDetailsFields,
  AccountFields,
} from "./components/base/Fields.js";
export default {
  components: {
    ShowScore,
    ShowData,
    SecurityHelp,
  },
  data() {
    return {
      workInProgress: false,
      toggle: {},
      results: [],
      details: [],
      header: [],
      detailsFields: {},
      fields: [],
      isLoading: false,
      lastGenerated: "",
      tips: [],
      selectedtips: "",
      selectedColumn: "",
    };
  },
  computed: {
    showFilter() {
      const params = this.$route.params;

      if (params.ORG) {
        return "Accounts for " + params.ORG;
      } else {
        return "All accounts";
      }
    },
    computedItems() {
      return this.results.map((item, id) => {
        return {
          ...item,
          id,
        };
      });
    },
  },
  async created() {
    var accessToken = await this.$auth.getAccessToken();
    if (
      this.$store.getters["portalParametersModule/kpisWorkInProgress"] ===
      undefined
    ) {
      await this.$store.dispatch("portalParametersModule/loadWipParameters", {
        refresh: true,
        accessToken: accessToken,
      });
    }
    this.workInProgress =
      this.$store.getters["portalParametersModule/kpisWorkInProgress"];
    if (!this.workInProgress) {
      this.loadKpireport(accessToken);
    }
  },
  methods: {
    rowClicked(_1, _2, columnname) {
      this.selectedColumn = columnname;
      this.selectedtips = this.tips[columnname.replace(/-/g, "")];
    },
    getScore(element, header, valueok) {
      return getBaseScore(element, header, valueok);
    },

    toggleRows(detail) {
      this.fields = insertDetailsFields(
        detail,
        this.fields,
        this.toggle[detail],
        this.detailsFields[detail]
      );
      this.toggle[detail] = !this.toggle[detail];
    },
    async loadKpireport(accessToken, refresh = false) {
      this.isLoading = true;
      const table = document.querySelector(".table");
      // var accessToken = await this.$auth.getAccessToken();
      await this.$store.dispatch("kpiReportModule/loadKpiReport", {
        refresh: refresh,
        accessToken: accessToken,
      });
      this.isLoading = false;
      table.style.display = "block";

      const data = this.$store.getters["kpiReportModule/kpiDetailedReport"];

      let retval = Object.keys(data[0]);
      retval = Array.from(new Set(retval));

      this.fields = AccountFields;
      this.detailsFields = AccountDetailsFields;
      this.tips = defaultTips;

      this.lastGenerated =
        this.$store.getters["kpiReportModule/kpiDateGenerated"];
      const params = this.$route.params;
      const results = Array.from(new Set(data));
      // if (params.BE && params.BU && params.ORG) {
      if (params.ORG) {
        const filtered = results.filter(
          (row) =>
            // row.BE === params.BE &&
            // row.BU === params.BU &&
            row.OrgId === params.ORG
        );
        this.results = filtered;
      } else {
        this.results = results;
      }

      this.headers = retval.sort();
      for (let i in this.headers) {
        this.toggle[this.headers[i]] = false;
      }
    },
  },
};
</script>
