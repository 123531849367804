var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.workInProgress)?_c('div',[_c('h1',[_vm._v("WORK IN PROGRESS")])]):_c('div',[_c('CCard',[_c('CCardHeader',[_c('h4',[_vm._v(" "+_vm._s(_vm.showFilter)+"("+_vm._s(" Last Generated: " + _vm.lastGenerated)+") "),_c('div',{staticClass:"float-right"},[_vm._v(" Latest "),_c('b',[_c('a',{on:{"click":function($event){return _vm.$router.push({ path: '/dashboard' })}}},[_vm._v("Changelog")])])])]),_c('security-help',{attrs:{"text":_vm.selectedtips,"selectedColumn":_vm.selectedColumn}})],1),_c('CCardBody',{staticClass:"Resizable-KPIs Resizable-KPIs-Accounts"},[_c('CDataTable',{attrs:{"items":_vm.computedItems,"fields":_vm.fields,"items-per-page-select":"","items-per-page":10,"loading":_vm.isLoading,"column-filter":"","table-filter":"","sorter":"","hover":"","striped":"","small":"","pagination":"","clickableRows":""},on:{"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"AccountAlias",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"Sticky-Column-KPI"},[_vm._v(" "+_vm._s(item.AccountAlias)+" ")])]}},{key:"score",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2",staticStyle:{"font-weight":"900"}},[_vm._v(_vm._s(item.Score))])]}},{key:"CERTIFIEDREPORT",fn:function(ref){
var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.CERTIFIEDREPORT, 'CERTIFIEDREPORT'),"toggle":_vm.toggle.CERTIFIEDREPORT},on:{"show-hide":function($event){return _vm.toggleRows('CERTIFIEDREPORT')}}})]}},{key:"CERTIFIEDREPORT-Score",fn:function(ref){
var item = ref.item;
return [_c('show-data',{attrs:{"data":item.CERTIFIEDREPORT.Score,"FormatValueExceptions":false,"check":item.CERTIFIEDREPORT.Score === 0}})]}},{key:"MFA",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[(item.MFA !== null)?_c('CButton',{attrs:{"color":item.MFA === 1 ? 'success' : 'danger',"height":"0.8rem","size":"sm"}},[_vm._v(_vm._s(item.MFA === 1 ? "V" : "X"))]):_c('CButton',{attrs:{"size":"sm","variant":"outline","color":"info","height":"0.8rem"}},[_vm._v(" "+_vm._s("N/A")+" ")])],1)]}},{key:"CT",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[(item.CT !== null)?_c('CButton',{attrs:{"color":item.CT >= 1 ? 'success' : 'danger',"height":"0.8rem","size":"sm"}},[_vm._v(_vm._s(item.CT >= 1 ? "V" : "X"))]):_c('CButton',{attrs:{"size":"sm","variant":"outline","color":"info","height":"0.8rem"}},[_vm._v(" "+_vm._s("N/A")+" ")])],1)]}},{key:"TA",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[(item.TA !== null)?_c('CButton',{attrs:{"color":item.TA >= 1 ? 'success' : 'danger',"height":"0.8rem","size":"sm"}},[_vm._v(_vm._s(item.TA >= 1 ? "V" : "X"))]):_c('CButton',{attrs:{"size":"sm","variant":"outline","color":"info","height":"0.8rem"}},[_vm._v(" "+_vm._s("N/A")+" ")])],1)]}},{key:"IAM",fn:function(ref){
var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.IAM, 'IAM'),"toggle":_vm.toggle.IAM},on:{"show-hide":function($event){return _vm.toggleRows('IAM')}}})]}},{key:"IAM-SCORE",fn:function(ref){
var item = ref.item;
return [_c('show-data',{attrs:{"data":item.IAM.Score,"check":item.IAM.Score === 0}})]}},{key:"IAM-USER",fn:function(ref){
var item = ref.item;
return [_c('show-data',{attrs:{"data":item.IAM.User,"FormatValueExceptions":true,"check":item.IAM.User.Value === 0}})]}},{key:"IAM-AGE",fn:function(ref){
var item = ref.item;
return [_c('show-data',{attrs:{"data":item.IAM.Age,"FormatValueExceptions":false,"check":item.IAM.Age === 0}})]}},{key:"IAM-ADMIN",fn:function(ref){
var item = ref.item;
return [_c('show-data',{attrs:{"data":item.IAM.Admin,"FormatValueExceptions":false,"check":item.IAM.Admin === 0}})]}},{key:"IAM-SVCCONSOLE",fn:function(ref){
var item = ref.item;
return [_c('show-data',{attrs:{"data":item.IAM.SvcConsole,"FormatValueExceptions":true,"check":item.IAM.SvcConsole.Value === 0}})]}},{key:"IAM-NOBOUNDARY",fn:function(ref){
var item = ref.item;
return [_c('show-data',{attrs:{"data":item.IAM.NoBoundary,"FormatValueExceptions":true,"check":item.IAM.NoBoundary.Value === 0}})]}},{key:"S3",fn:function(ref){
var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.S3, 'S3-ACCOUNT'),"toggle":_vm.toggle.S3},on:{"show-hide":function($event){return _vm.toggleRows('S3')}}})]}},{key:"S3-Total",fn:function(ref){
var item = ref.item;
return [_c('show-data',{attrs:{"data":item.S3.Total,"FormatValueExceptions":false,"check":item.S3.Total,"score":_vm.getScore(item.S3, 'S3-ACCOUNT'),"isTotal":""}})]}},{key:"S3-UnEncrypted",fn:function(ref){
var item = ref.item;
return [_c('show-data',{attrs:{"data":item.S3.UnEncrypted,"FormatValueExceptions":true,"check":item.S3.UnEncrypted.Value === 0,"score":_vm.getScore(item.S3, 'S3-ACCOUNT')}})]}},{key:"S3-EncryptionInTransit",fn:function(ref){
var item = ref.item;
return [_c('show-data',{attrs:{"data":item.S3.EncryptionInTransit,"FormatValueExceptions":true,"check":item.S3.EncryptionInTransit.Value === 0,"score":_vm.getScore(item.S3, 'S3-ACCOUNT')}})]}},{key:"S3-Public",fn:function(ref){
var item = ref.item;
return [_c('show-data',{attrs:{"data":item.S3.Public,"FormatValueExceptions":true,"check":item.S3.Public.Value === 0,"score":_vm.getScore(item.S3, 'S3-ACCOUNT')}})]}},{key:"S3-CanBePublic",fn:function(ref){
var item = ref.item;
return [_c('show-data',{attrs:{"data":item.S3.CanBePublic,"FormatValueExceptions":true,"check":item.S3.CanBePublic.Value === 0,"score":_vm.getScore(item.S3, 'S3-ACCOUNT')}})]}},{key:"S3-AccessPoint-Total",fn:function(ref){
var item = ref.item;
return [_c('show-data',{attrs:{"data":item.S3.AccessPoint.Total,"FormatValueExceptions":false,"isTotal":"","check":item.S3.AccessPoint.Total.Value === 0,"score":_vm.getScore(item.S3, 'S3-ACCOUNT')}})]}},{key:"S3-AccessPoint-Public",fn:function(ref){
var item = ref.item;
return [_c('show-data',{attrs:{"data":item.S3.AccessPoint.Public,"FormatValueExceptions":true,"check":item.S3.AccessPoint.Public.Value === 0,"score":_vm.getScore(item.S3, 'S3-ACCOUNT')}})]}},{key:"S3-AccessPoint-CanBePublic",fn:function(ref){
var item = ref.item;
return [_c('show-data',{attrs:{"data":item.S3.AccessPoint.CanBePublic,"FormatValueExceptions":true,"check":item.S3.AccessPoint.CanBePublic.Value === 0,"score":_vm.getScore(item.S3, 'S3-ACCOUNT')}})]}},{key:"RDS",fn:function(ref){
var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.RDS, 'RDS-ACCOUNT'),"toggle":_vm.toggle.RDS},on:{"show-hide":function($event){return _vm.toggleRows('RDS')}}})]}},{key:"RDS-Total",fn:function(ref){
var item = ref.item;
return [_c('show-data',{attrs:{"data":item.RDS.Total,"FormatValueExceptions":false,"isTotal":"","check":item.RDS.Total,"score":_vm.getScore(item.RDS, 'RDS-ACCOUNT')}})]}},{key:"RDS-UnEncrypted",fn:function(ref){
var item = ref.item;
return [_c('show-data',{attrs:{"data":item.RDS.UnEncrypted,"FormatValueExceptions":true,"check":item.RDS.UnEncrypted.Value === 0,"score":_vm.getScore(item.RDS, 'RDS-ACCOUNT')}})]}},{key:"RDS-Public",fn:function(ref){
var item = ref.item;
return [_c('show-data',{attrs:{"data":item.RDS.Public,"FormatValueExceptions":true,"check":item.RDS.Public.Value === 0,"score":_vm.getScore(item.RDS, 'RDS-ACCOUNT')}})]}},{key:"EBS",fn:function(ref){
var item = ref.item;
return [_c('show-score',{attrs:{"score":item.EBS.Total === 0
                ? 'N/A'
                : _vm.getScore(item.EBS.UnEncrypted, 'ONE', 0),"toggle":_vm.toggle.EBS},on:{"show-hide":function($event){return _vm.toggleRows('EBS')}}})]}},{key:"EBS-Total",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EBS.Total,"FormatValueExceptions":false,"check":item.EBS.Total,"isTotal":"","score":item.EBS.Total === 0
                ? 'N/A'
                : _vm.getScore(item.EBS.UnEncrypted, 'ONE', 0)}})]}},{key:"EBS-UnEncrypted",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EBS.UnEncrypted,"FormatValueExceptions":true,"check":item.EBS.UnEncrypted.Value === 0,"score":item.EBS.Total === 0
                ? 'N/A'
                : _vm.getScore(item.EBS.UnEncrypted, 'ONE', 0)}})]}},{key:"EFS",fn:function(ref){
                var item = ref.item;
return [_c('show-score',{attrs:{"score":item.EFS.Total === 0
                ? 'N/A'
                : _vm.getScore(item.EFS.UnEncrypted, 'ONE', 0),"toggle":_vm.toggle.EFS},on:{"show-hide":function($event){return _vm.toggleRows('EFS')}}})]}},{key:"EFS-Total",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EFS.Total,"FormatValueExceptions":false,"check":item.EFS.Total,"isTotal":"","score":item.EFS.Total === 0
                ? 'N/A'
                : _vm.getScore(item.EFS.UnEncrypted, 'ONE', 0)}})]}},{key:"EFS-UnEncrypted",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EFS.UnEncrypted,"FormatValueExceptions":true,"check":item.EFS.UnEncrypted.Value === 0,"score":item.EFS.Total === 0
                ? 'N/A'
                : _vm.getScore(item.EFS.UnEncrypted, 'ONE', 0)}})]}},{key:"FSX",fn:function(ref){
                var item = ref.item;
return [_c('show-score',{attrs:{"score":item.FSX.Total === 0
                ? 'N/A'
                : _vm.getScore(item.FSX.UnEncrypted, 'ONE', 0),"toggle":_vm.toggle.FSX},on:{"show-hide":function($event){return _vm.toggleRows('FSX')}}})]}},{key:"FSX-Total",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.FSX.Total,"FormatValueExceptions":false,"check":item.FSX.Total,"isTotal":"","score":item.FSX.Total === 0
                ? 'N/A'
                : _vm.getScore(item.FSX.UnEncrypted, 'ONE', 0)}})]}},{key:"FSX-UnEncrypted",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.FSX.UnEncrypted,"FormatValueExceptions":true,"check":item.FSX.UnEncrypted.Value === 0,"score":item.FSX.Total === 0
                ? 'N/A'
                : _vm.getScore(item.FSX.UnEncrypted, 'ONE', 0)}})]}},{key:"NatGateway",fn:function(ref){
                var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.NatGateway, 'NatGateway-Account', 0),"toggle":_vm.toggle.NatGateway},on:{"show-hide":function($event){return _vm.toggleRows('NatGateway')}}})]}},{key:"NatGateway-Total",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.NatGateway.Total,"FormatValueExceptions":true,"check":item.NatGateway.Total.Value === 0,"isTotal":""}})]}},{key:"NatGateway-NETWORKFIREWALL-NotAttached",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.NatGateway.NETWORKFIREWALL.NotAttached,"FormatValueExceptions":true,"check":item.NatGateway.NETWORKFIREWALL.NotAttached.Value === 0}})]}},{key:"NatGateway-NETWORKFIREWALL-BadNetworkFirewall",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.NatGateway.NETWORKFIREWALL.BadNetworkFirewall,"FormatValueExceptions":true,"check":item.NatGateway.NETWORKFIREWALL.BadNetworkFirewall.Value === 0}})]}},{key:"EC2",fn:function(ref){
                var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.EC2, 'EC2-Account', 0),"toggle":_vm.toggle.EC2},on:{"show-hide":function($event){return _vm.toggleRows('EC2')}}})]}},{key:"EC2-Total",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.Total,"FormatValueExceptions":false,"check":item.EC2.Total,"isTotal":"","score":_vm.getScore(item.EC2, 'EC2-Account', 0)}})]}},{key:"EC2-SSHRDP_Public",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.SSHRDP_Public,"FormatValueExceptions":true,"check":item.EC2.SSHRDP_Public.Value === 0,"score":_vm.getScore(item.EC2, 'EC2-Account', 0)}})]}},{key:"EC2-SSHRDP_Private",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.SSHRDP_Private,"FormatValueExceptions":true,"isTotal":""}})]}},{key:"EC2-UNWANTEDPORTS_Public",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.UNWANTEDPORTS_Public,"FormatValueExceptions":true,"check":item.EC2.UNWANTEDPORTS_Public.Value === 0,"score":_vm.getScore(item.EC2, 'EC2-Account', 0)}})]}},{key:"EC2-UNWANTEDPORTS_Private",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.UNWANTEDPORTS_Private,"FormatValueExceptions":true,"isTotal":""}})]}},{key:"EC2-SG00_Public",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.SG00_Public,"FormatValueExceptions":true,"check":item.EC2.SG00_Public.Value === 0,"score":_vm.getScore(item.EC2, 'EC2-Account', 0)}})]}},{key:"EC2-SG00_Private",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.SG00_Private,"FormatValueExceptions":true,"isTotal":""}})]}},{key:"EC2-AutoscaledVMs",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.AutoscaledVMs,"FormatValueExceptions":false,"isTotal":""}})]}},{key:"EC2-Public",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.Public,"FormatValueExceptions":true,"check":item.EC2.Public.Value === 0,"score":_vm.getScore(item.EC2, 'EC2-Account', 0)}})]}},{key:"EC2-Metadata-IMDSv2NotEnforced",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.Metadata.IMDSv2NotEnforced,"FormatValueExceptions":true,"check":item.EC2.Metadata.IMDSv2NotEnforced.Value === 0,"score":_vm.getScore(item.EC2, 'EC2-Account', 0)}})]}},{key:"EC2-SSMAgent",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.SSMAgent,"FormatValueExceptions":true,"check":item.EC2.SSMAgent.Value === 0,"score":_vm.getScore(item.EC2, 'EC2-Account', 0)}})]}},{key:"EC2-SSMAgent-NotInstalled",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.SSMAgent.NotInstalled,"FormatValueExceptions":true,"check":item.EC2.SSMAgent.NotInstalled.Value === 0,"score":_vm.getScore(item.EC2, 'EC2-Account', 0),"isTotal":""}})]}},{key:"EC2-SSMAgent-NotReachable",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.SSMAgent.NotReachable,"FormatValueExceptions":true,"check":item.EC2.SSMAgent.NotReachable.Value === 0,"score":_vm.getScore(item.EC2, 'EC2-Account', 0),"isTotal":""}})]}},{key:"EC2-SSMAgent-NotUptodate",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.SSMAgent.NotUptodate,"FormatValueExceptions":true,"check":item.EC2.SSMAgent.NotUptodate.Value === 0,"score":_vm.getScore(item.EC2, 'EC2-Account', 0),"isTotal":""}})]}},{key:"EC2-Permissions",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.Permissions,"FormatValueExceptions":true,"check":item.EC2.Permissions.Value === 0,"score":_vm.getScore(item.EC2, 'EC2-Account', 0)}})]}},{key:"EC2-Permissions-Admin",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.Permissions.Admin,"FormatValueExceptions":true,"check":item.EC2.Permissions.Admin.Value === 0,"score":_vm.getScore(item.EC2, 'EC2-Account', 0),"isTotal":""}})]}},{key:"EC2-Permissions-PowerUser",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.Permissions.PowerUser,"FormatValueExceptions":true,"check":item.EC2.Permissions.PowerUser.Value === 0,"score":_vm.getScore(item.EC2, 'EC2-Account', 0),"isTotal":""}})]}},{key:"EC2-InspectorV2-MissingScan",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.InspectorV2.MissingScan,"FormatValueExceptions":true,"check":item.EC2.InspectorV2.MissingScan.Value === 0,"score":_vm.getScore(item.EC2, 'EC2-Account', 0),"isTotal":""}})]}},{key:"EC2-Inspector-UnsupportedRegion",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.Inspector.NeverRun.UnsupportedRegion,"FormatValueExceptions":false,"check":item.EC2.Inspector.NeverRun.UnsupportedRegion.Value === 0,"score":_vm.getScore(item.EC2, 'EC2-Account', 0),"isTotal":""}})]}},{key:"EC2-Inspector-NeverRun",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.Inspector.NeverRun,"FormatValueExceptions":true,"check":item.EC2.Inspector.NeverRun.Value === 0,"score":_vm.getScore(item.EC2, 'EC2-Account', 0),"isTotal":""}})]}},{key:"EC2-Inspector-TooOld",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.Inspector.TooOld,"FormatValueExceptions":true,"check":item.EC2.Inspector.TooOld.Value === 0,"score":_vm.getScore(item.EC2, 'EC2-Account', 0),"isTotal":""}})]}},{key:"EC2-Inspector-Good",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.Inspector.Good,"FormatValueExceptions":true,"check":item.EC2.Inspector.Good.Value ===
              item.EC2.InspectorV2.MissingScan.Value,"score":_vm.getScore(item.EC2, 'EC2-Account', 0),"isTotal":""}})]}},{key:"EC2-Inspector-GooodWithAgent",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.EC2.Inspector.GooodWithAgent,"FormatValueExceptions":true,"check":item.EC2.Inspector.GooodWithAgent.Value ===
              item.EC2.InspectorV2.MissingScan.Value,"score":_vm.getScore(item.EC2, 'EC2-Account', 0)}})]}},{key:"Inspector",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.Inspector, 'Inspector-Account', 0),"toggle":_vm.toggle.Inspector},on:{"show-hide":function($event){return _vm.toggleRows('Inspector')}}})]}},{key:"Inspector-Findings-Critical",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.Inspector.Findings.Critical,"FormatValueExceptions":false,"check":item.Inspector.Findings.Critical === 0,"score":_vm.getScore(item.Inspector, 'Inspector-Account', 0)}})]}},{key:"Inspector-Findings-High",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.Inspector.Findings.High,"FormatValueExceptions":false,"check":item.Inspector.Findings.High === 0,"score":_vm.getScore(item.Inspector, 'Inspector-Account', 0)}})]}},{key:"XLB",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":item.XLB.Total === 0
                ? 'N/A'
                : _vm.getScore(item.XLB.UnEncrypted, 'ONE', 0),"toggle":_vm.toggle.XLB},on:{"show-hide":function($event){return _vm.toggleRows('XLB')}}})]}},{key:"XLB-Total",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.XLB.Total,"FormatValueExceptions":false,"check":item.XLB.Total,"isTotal":"","score":item.XLB.Total === 0
                ? 'N/A'
                : _vm.getScore(item.XLB.UnEncrypted, 'ONE', 0)}})]}},{key:"XLB-UnEncrypted",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.XLB.UnEncrypted,"FormatValueExceptions":true,"check":item.XLB.UnEncrypted.Value === 0,"score":item.XLB.Total === 0
                ? 'N/A'
                : _vm.getScore(item.XLB.UnEncrypted, 'ONE', 0)}})]}},{key:"Subnet",fn:function(ref){
                var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.Subnet, 'Subnet-Account', 0),"toggle":_vm.toggle.Subnet},on:{"show-hide":function($event){return _vm.toggleRows('Subnet')}}})]}},{key:"Subnet-Total",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.Subnet.Total,"isTotal":"","FormatValueExceptions":false,"check":item.Subnet.Total,"score":_vm.getScore(item.Subnet, 'Subnet-Account', 0)}})]}},{key:"Subnet-DefaultNACL",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.Subnet.DefaultNACL,"FormatValueExceptions":true,"check":item.Subnet.DefaultNACL.Value === 0,"score":_vm.getScore(item.Subnet, 'Subnet-Account', 0)}})]}},{key:"Subnet-InternetFacing",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.Subnet.InternetFacing,"FormatValueExceptions":true,"check":item.Subnet.InternetFacing.Value === 0,"score":_vm.getScore(item.Subnet, 'Subnet-Account', 0)}})]}},{key:"Subnet-WithOpenPortsNotBehindSG",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.Subnet.WithOpenPortsNotBehindSG,"FormatValueExceptions":true,"check":item.Subnet.WithOpenPortsNotBehindSG.Value === 0,"score":_vm.getScore(item.Subnet, 'Subnet-Account', 0),"isTotal":""}})]}},{key:"Subnet-Public-WithOpenPortsNotBehindSG",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.Subnet.Public.WithOpenPortsNotBehindSG,"FormatValueExceptions":true,"check":item.Subnet.Public.WithOpenPortsNotBehindSG.Value === 0,"score":_vm.getScore(item.Subnet, 'Subnet-Account', 0)}})]}},{key:"Subnet-Public-Total",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.Subnet.Public.Total,"FormatValueExceptions":false,"check":item.Subnet.Public.Total,"isTotal":"","score":_vm.getScore(item.Subnet, 'Subnet-Account', 0)}})]}},{key:"Subnet-Private-WithOpenPortsNotBehindSG",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.Subnet.Private.WithOpenPortsNotBehindSG,"FormatValueExceptions":true,"check":item.Subnet.Private.WithOpenPortsNotBehindSG.Value === 0,"isTotal":"","score":_vm.getScore(item.Subnet, 'Subnet-Account', 0)}})]}},{key:"Subnet-Private-Total",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.Subnet.Private.Total,"FormatValueExceptions":false,"check":item.Subnet.Private.Total,"isTotal":"","score":_vm.getScore(item.Subnet, 'Subnet-Account', 0)}})]}},{key:"CloudFront",fn:function(ref){
                var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.CloudFront, 'CloudFront-ACCOUNT', 0),"toggle":_vm.toggle.CloudFront},on:{"show-hide":function($event){return _vm.toggleRows('CloudFront')}}})]}},{key:"CloudFront-Total",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.CloudFront.Total,"FormatValueExceptions":false,"check":item.CloudFront.Total,"isTotal":"","score":_vm.getScore(item.CloudFront, 'CloudFront-ACCOUNT', 0)}})]}},{key:"CloudFront-TLS",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.CloudFront.TLS,"FormatValueExceptions":true,"check":item.CloudFront.TLS.Value === 0,"score":_vm.getScore(item.CloudFront, 'CloudFront-ACCOUNT', 0)}})]}},{key:"CloudFront-WithWAFv2",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.CloudFront.WithWAFv2,"FormatValueExceptions":true,"isTotal":""}})]}},{key:"CloudFront-WithWAFv2Valid",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.CloudFront.WithWAFv2Valid,"FormatValueExceptions":true,"isTotal":""}})]}},{key:"CloudFront-WithWAFv2Counting",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.CloudFront.WithWAFv2Counting,"FormatValueExceptions":true,"isTotal":""}})]}},{key:"CloudFront-WAFAnyVersionValid",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.CloudFront.WAFAnyVersionValid,"FormatValueExceptions":true,"check":item.CloudFront.WAFAnyVersionValid.Value +
                item.CloudFront.WAFAnyVersionValid.Exceptions ===
              item.CloudFront.Total}})]}},{key:"PubResource",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.PubResource, 'PubResource-ACCOUNT', 0),"toggle":_vm.toggle.PubResource},on:{"show-hide":function($event){return _vm.toggleRows('PubResource')}}})]}},{key:"PubResource-Total",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.PubResource.Total,"FormatValueExceptions":false,"check":item.PubResource.Total,"isTotal":"","score":_vm.getScore(item.PubResource, 'PubResource-ACCOUNT', 0)}})]}},{key:"PubResource-ShieldAndDRT",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.PubResource.ShieldAndDRT,"FormatValueExceptions":true,"check":item.PubResource.ShieldAndDRT.Value +
                item.PubResource.ShieldAndDRT.Exceptions ===
              item.PubResource.Total,"score":_vm.getScore(item.PubResource, 'PubResource-ACCOUNT', 0)}})]}},{key:"PubResource-Shield",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.PubResource.Shield,"FormatValueExceptions":true,"isTotal":""}})]}},{key:"PubResource-NoShield",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.PubResource.NoShield,"FormatValueExceptions":true,"check":item.PubResource.NoShield.Value === 0,"score":_vm.getScore(item.PubResource, 'ONE', 0),"isTotal":""}})]}},{key:"PubResource-InternetFacing",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.PubResource.InternetFacing,"FormatValueExceptions":true,"check":item.PubResource.InternetFacing.Value === 0,"score":_vm.getScore(item.PubResource, 'ONE', 0)}})]}},{key:"PubResource-ShieldTotal",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.PubResource.ShieldTotal,"FormatValueExceptions":false,"check":item.PubResource.ShieldTotal.Value,"isTotal":"","score":_vm.getScore(item.PubResource, 'ONE', 0)}})]}},{key:"PubAppAndAPI",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.PubAppAndAPI, 'PubAppAndAPI-ACCOUNT', 0),"toggle":_vm.toggle.PubAppAndAPI},on:{"show-hide":function($event){return _vm.toggleRows('PubAppAndAPI')}}})]}},{key:"PubAppAndAPI-Total",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.PubAppAndAPI.Total,"FormatValueExceptions":false,"check":item.PubAppAndAPI.Total,"isTotal":"","score":_vm.getScore(item.PubAppAndAPI, 'PubAppAndAPI-ACCOUNT', 0)}})]}},{key:"PubAppAndAPI-TLS",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.PubAppAndAPI.TLS,"FormatValueExceptions":true,"check":item.PubAppAndAPI.TLS.Value === 0,"score":_vm.getScore(item.PubAppAndAPI, 'PubAppAndAPI-ACCOUNT', 0)}})]}},{key:"PubAppAndAPI-NoWAFv2",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.PubAppAndAPI.NoWAFv2,"FormatValueExceptions":true,"isTotal":""}})]}},{key:"PubAppAndAPI-WAFv2",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.PubAppAndAPI.WAFv2,"FormatValueExceptions":true,"isTotal":""}})]}},{key:"PubAppAndAPI-WAFv2Valid",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.PubAppAndAPI.WAFv2Valid,"FormatValueExceptions":true,"isTotal":""}})]}},{key:"PubAppAndAPI-WAFv2Counting",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.PubAppAndAPI.WAFv2Counting,"FormatValueExceptions":true,"isTotal":""}})]}},{key:"PubAppAndAPI-WAFAnyVersionValid",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.PubAppAndAPI.WAFAnyVersionValid,"FormatValueExceptions":true,"check":item.PubAppAndAPI.WAFAnyVersionValid.Value +
                item.PubAppAndAPI.WAFAnyVersionValid.Exceptions ===
              item.PubAppAndAPI.Total,"score":_vm.getScore(item.PubAppAndAPI, 'PubAppAndAPI-ACCOUNT', 0)}})]}},{key:"DYNAMODB",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":item.DYNAMODB.Total === 0
                ? 'N/A'
                : _vm.getScore(item.DYNAMODB.NoEncryption, 'ONE', 0),"toggle":_vm.toggle.DYNAMODB},on:{"show-hide":function($event){return _vm.toggleRows('DYNAMODB')}}})]}},{key:"DYNAMODB-Total",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.DYNAMODB.Total,"FormatValueExceptions":false,"isTotal":"","check":item.DYNAMODB.Total,"score":item.DYNAMODB.Total === 0
                ? 'N/A'
                : _vm.getScore(item.DYNAMODB.NoEncryption, 'ONE', 0)}})]}},{key:"DYNAMODB-NoEncryption",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.DYNAMODB.NoEncryption,"FormatValueExceptions":true,"check":item.DYNAMODB.NoEncryption.Value === 0,"score":item.DYNAMODB.Total === 0
                ? 'N/A'
                : _vm.getScore(item.DYNAMODB.NoEncryption, 'ONE', 0)}})]}},{key:"ELASTIC_SEARCH",fn:function(ref){
                var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.ELASTIC_SEARCH, 'ELASTIC_SEARCH-ACCOUNT', 0),"toggle":_vm.toggle.ELASTIC_SEARCH},on:{"show-hide":function($event){return _vm.toggleRows('ELASTIC_SEARCH')}}})]}},{key:"ELASTIC_SEARCH-Total",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.ELASTIC_SEARCH.Total,"FormatValueExceptions":false,"check":item.ELASTIC_SEARCH.Total,"isTotal":"","score":_vm.getScore(item.ELASTIC_SEARCH, 'ELASTIC_SEARCH-ACCOUNT', 0)}})]}},{key:"ELASTIC_SEARCH-BadTLS",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.ELASTIC_SEARCH.BadTLS,"FormatValueExceptions":true,"check":item.ELASTIC_SEARCH.BadTLS.Value === 0,"score":_vm.getScore(item.ELASTIC_SEARCH, 'ELASTIC_SEARCH-ACCOUNT', 0)}})]}},{key:"ELASTIC_SEARCH-NoEncryptionAtRest",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.ELASTIC_SEARCH.NoEncryptionAtRest,"FormatValueExceptions":true,"check":item.ELASTIC_SEARCH.NoEncryptionAtRest.Value === 0,"score":_vm.getScore(item.ELASTIC_SEARCH, 'ELASTIC_SEARCH-ACCOUNT', 0)}})]}},{key:"ELASTIC_SEARCH-NoEncryptionInTransit",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.ELASTIC_SEARCH.NoEncryptionInTransit,"FormatValueExceptions":true,"check":item.ELASTIC_SEARCH.NoEncryptionInTransit.Value === 0,"score":_vm.getScore(item.ELASTIC_SEARCH, 'ELASTIC_SEARCH-ACCOUNT', 0)}})]}},{key:"ELASTIC_SEARCH-NoNodeToNodeEncryption",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.ELASTIC_SEARCH.NoNodeToNodeEncryption,"FormatValueExceptions":true,"check":item.ELASTIC_SEARCH.NoNodeToNodeEncryption.Value === 0,"score":_vm.getScore(item.ELASTIC_SEARCH, 'ELASTIC_SEARCH-ACCOUNT', 0)}})]}},{key:"ELASTICACHE",fn:function(ref){
                var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.ELASTICACHE, 'ELASTICACHE-ACCOUNT', 0),"toggle":_vm.toggle.ELASTICACHE},on:{"show-hide":function($event){return _vm.toggleRows('ELASTICACHE')}}})]}},{key:"ELASTICACHE-Total",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.ELASTICACHE.Total,"FormatValueExceptions":false,"check":item.ELASTICACHE.Total,"isTotal":"","score":_vm.getScore(item.ELASTICACHE, 'ELASTICACHE-ACCOUNT', 0)}})]}},{key:"ELASTICACHE-NoEncryptionAtRest",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.ELASTICACHE.NoEncryptionAtRest,"FormatValueExceptions":true,"check":item.ELASTICACHE.NoEncryptionAtRest.Value === 0,"score":_vm.getScore(item.ELASTICACHE, 'ELASTICACHE-ACCOUNT', 0)}})]}},{key:"ELASTICACHE-NoEncryptionInTransit",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.ELASTICACHE.NoEncryptionInTransit,"FormatValueExceptions":true,"check":item.ELASTICACHE.NoEncryptionInTransit.Value === 0,"score":_vm.getScore(item.ELASTICACHE, 'ELASTICACHE-ACCOUNT', 0)}})]}},{key:"KINESIS",fn:function(ref){
                var item = ref.item;
return [_c('show-score',{attrs:{"score":item.KINESIS.Total === 0
                ? 'N/A'
                : _vm.getScore(item.KINESIS.NoEncryptionAtRest, 'ONE', 0),"toggle":_vm.toggle.KINESIS},on:{"show-hide":function($event){return _vm.toggleRows('KINESIS')}}})]}},{key:"KINESIS-Total",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.KINESIS.Total,"FormatValueExceptions":false,"check":item.KINESIS.Total,"isTotal":"","score":item.KINESIS.Total === 0
                ? 'N/A'
                : _vm.getScore(item.KINESIS.NoEncryptionAtRest, 'ONE', 0)}})]}},{key:"KINESIS-NoEncryptionAtRest",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.KINESIS.NoEncryptionAtRest,"FormatValueExceptions":true,"check":item.KINESIS.NoEncryptionAtRest.Value === 0,"score":item.KINESIS.Total === 0
                ? 'N/A'
                : _vm.getScore(item.KINESIS.NoEncryptionAtRest, 'ONE', 0)}})]}},{key:"KINESISFIREHOSE",fn:function(ref){
                var item = ref.item;
return [_c('show-score',{attrs:{"score":item.KINESISFIREHOSE.Total === 0
                ? 'N/A'
                : _vm.getScore(item.KINESISFIREHOSE.NoEncryptionAtRest, 'ONE', 0),"toggle":_vm.toggle.KINESISFIREHOSE},on:{"show-hide":function($event){return _vm.toggleRows('KINESISFIREHOSE')}}})]}},{key:"KINESISFIREHOSE-Total",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.KINESISFIREHOSE.Total,"FormatValueExceptions":false,"check":item.KINESISFIREHOSE.Total.Value,"isTotal":"","score":item.KINESISFIREHOSE.Total === 0
                ? 'N/A'
                : _vm.getScore(item.KINESISFIREHOSE.NoEncryptionAtRest, 'ONE', 0)}})]}},{key:"KINESISFIREHOSE-NoEncryptionAtRest",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.KINESISFIREHOSE.NoEncryptionAtRest,"FormatValueExceptions":true,"check":item.KINESISFIREHOSE.NoEncryptionAtRest.Value === 0,"score":item.KINESISFIREHOSE.Total === 0
                ? 'N/A'
                : _vm.getScore(item.KINESISFIREHOSE.NoEncryptionAtRest, 'ONE', 0)}})]}},{key:"SQS",fn:function(ref){
                var item = ref.item;
return [_c('show-score',{attrs:{"score":item.SQS.Total === 0
                ? 'N/A'
                : _vm.getScore(item.SQS.NoEncryptionAtRest, 'ONE', 0),"toggle":_vm.toggle.SQS},on:{"show-hide":function($event){return _vm.toggleRows('SQS')}}})]}},{key:"SQS-Total",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.SQS.Total,"FormatValueExceptions":false,"check":item.SQS.Total,"isTotal":"","score":item.SQS.Total === 0
                ? 'N/A'
                : _vm.getScore(item.SQS.NoEncryptionAtRest, 'ONE', 0)}})]}},{key:"SQS-NoEncryptionAtRest",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.SQS.NoEncryptionAtRest,"FormatValueExceptions":true,"check":item.SQS.NoEncryptionAtRest.Value === 0,"score":item.SQS.Total === 0
                ? 'N/A'
                : _vm.getScore(item.SQS.NoEncryptionAtRest, 'ONE', 0)}})]}},{key:"BACKUP",fn:function(ref){
                var item = ref.item;
return [_c('show-score',{attrs:{"score":item.BACKUP.Total === 0
                ? 'N/A'
                : _vm.getScore(item.BACKUP.NoEncryptionAtRest, 'ONE', 0),"toggle":_vm.toggle.BACKUP},on:{"show-hide":function($event){return _vm.toggleRows('BACKUP')}}})]}},{key:"BACKUP-Total",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.BACKUP.Total,"FormatValueExceptions":false,"check":item.BACKUP.Total,"isTotal":"","score":item.BACKUP.Total === 0
                ? 'N/A'
                : _vm.getScore(item.BACKUP.NoEncryptionAtRest, 'ONE', 0)}})]}},{key:"BACKUP-NoEncryptionAtRest",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.BACKUP.NoEncryptionAtRest,"FormatValueExceptions":true,"check":item.BACKUP.NoEncryptionAtRest.Value === 0,"score":item.BACKUP.Total === 0
                ? 'N/A'
                : _vm.getScore(item.BACKUP.NoEncryptionAtRest, 'ONE', 0)}})]}},{key:"XRAY",fn:function(ref){
                var item = ref.item;
return [_c('show-score',{attrs:{"score":item.XRAY.Total === 0
                ? 'N/A'
                : _vm.getScore(item.XRAY.NoEncryption, 'ONE', 0),"toggle":_vm.toggle.XRAY},on:{"show-hide":function($event){return _vm.toggleRows('XRAY')}}})]}},{key:"XRAY-Total",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.XRAY.Total,"FormatValueExceptions":false,"check":item.XRAY.Total,"isTotal":"","score":item.XRAY.Total === 0
                ? 'N/A'
                : _vm.getScore(item.XRAY.NoEncryption, 'ONE', 0)}})]}},{key:"XRAY-NoEncryption",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.XRAY.NoEncryption,"FormatValueExceptions":true,"check":item.XRAY.NoEncryption.Value === 0,"score":item.XRAY.Total === 0
                ? 'N/A'
                : _vm.getScore(item.XRAY.NoEncryption, 'ONE', 0)}})]}},{key:"REDSHIFT",fn:function(ref){
                var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.REDSHIFT, 'REDSHIFT-ACCOUNT'),"toggle":_vm.toggle.REDSHIFT},on:{"show-hide":function($event){return _vm.toggleRows('REDSHIFT')}}})]}},{key:"REDSHIFT-Total",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.REDSHIFT.Total,"FormatValueExceptions":false,"check":item.REDSHIFT.Total,"isTotal":"","score":_vm.getScore(item.REDSHIFT, 'REDSHIFT-ACCOUNT')}})]}},{key:"REDSHIFT-NoEncryptionAtRest",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.REDSHIFT.NoEncryptionAtRest,"FormatValueExceptions":true,"check":item.REDSHIFT.NoEncryptionAtRest.Value === 0,"score":_vm.getScore(item.REDSHIFT, 'REDSHIFT-ACCOUNT')}})]}},{key:"REDSHIFT-Public",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.REDSHIFT.Public,"FormatValueExceptions":true,"check":item.REDSHIFT.Public.Value === 0,"score":_vm.getScore(item.REDSHIFT, 'REDSHIFT-ACCOUNT')}})]}},{key:"SAGEMAKER_NOTEBOOKINSTANCE",fn:function(ref){
                var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(
                item.SAGEMAKER_NOTEBOOKINSTANCE,
                'SAGEMAKER_NOTEBOOKINSTANCE-Account',
                100
              ),"toggle":_vm.toggle.SAGEMAKER_NOTEBOOKINSTANCE},on:{"show-hide":function($event){return _vm.toggleRows('SAGEMAKER_NOTEBOOKINSTANCE')}}})]}},{key:"SAGEMAKER_NOTEBOOKINSTANCE-Total",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.SAGEMAKER_NOTEBOOKINSTANCE.Total,"FormatValueExceptions":false,"check":item.SAGEMAKER_NOTEBOOKINSTANCE.Total,"isTotal":"","score":_vm.getScore(
                item.SAGEMAKER_NOTEBOOKINSTANCE,
                'SAGEMAKER_NOTEBOOKINSTANCE-Account',
                100
              )}})]}},{key:"SAGEMAKER_NOTEBOOKINSTANCE-DirectInternetAccess",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.SAGEMAKER_NOTEBOOKINSTANCE.DirectInternetAccess,"FormatValueExceptions":true,"check":item.SAGEMAKER_NOTEBOOKINSTANCE.DirectInternetAccess.Value === 0}})]}},{key:"SAGEMAKER_NOTEBOOKINSTANCE-RootAccess",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.SAGEMAKER_NOTEBOOKINSTANCE.RootAccess,"FormatValueExceptions":true,"check":item.SAGEMAKER_NOTEBOOKINSTANCE.RootAccess.Value === 0,"score":_vm.getScore(
                item.SAGEMAKER_NOTEBOOKINSTANCE,
                'SAGEMAKER_NOTEBOOKINSTANCE-Account',
                100
              )}})]}},{key:"SAGEMAKER_TRAININGJOB",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(
                item.SAGEMAKER_TRAININGJOB,
                'SAGEMAKER_TRAININGJOB-ACCOUNT',
                0
              ),"toggle":_vm.toggle.SAGEMAKER_TRAININGJOB},on:{"show-hide":function($event){return _vm.toggleRows('SAGEMAKER_TRAININGJOB')}}})]}},{key:"SAGEMAKER_TRAININGJOB-Total",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.SAGEMAKER_TRAININGJOB.Total,"FormatValueExceptions":false,"check":item.SAGEMAKER_TRAININGJOB.Total,"isTotal":"","score":_vm.getScore(
                item.SAGEMAKER_TRAININGJOB,
                'SAGEMAKER_TRAININGJOB-ACCOUNT',
                100
              )}})]}},{key:"SAGEMAKER_TRAININGJOB-NoEnableInterContainerTrafficEncryption",fn:function(ref){
          var item = ref.item;
return [_c('show-data',{attrs:{"data":item.SAGEMAKER_TRAININGJOB
                .NoEnableInterContainerTrafficEncryption,"FormatValueExceptions":true,"score":_vm.getScore(
                item.SAGEMAKER_TRAININGJOB,
                'SAGEMAKER_TRAININGJOB-ACCOUNT',
                0
              ),"check":item.SAGEMAKER_TRAININGJOB
                .NoEnableInterContainerTrafficEncryption.Value === 0}})]}},{key:"SAGEMAKER_TRAININGJOB-NoNetworkIsolation",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.SAGEMAKER_TRAININGJOB.NoNetworkIsolation,"score":_vm.getScore(
                item.SAGEMAKER_TRAININGJOB,
                'SAGEMAKER_TRAININGJOB-ACCOUNT',
                0
              ),"FormatValueExceptions":true,"check":item.SAGEMAKER_TRAININGJOB.NoNetworkIsolation.Value === 0}})]}},{key:"WORKSPACES",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.WORKSPACES, 'WORKSPACES-ACCOUNT'),"toggle":_vm.toggle.WORKSPACES},on:{"show-hide":function($event){return _vm.toggleRows('WORKSPACES')}}})]}},{key:"WORKSPACES-Total",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.WORKSPACES.Total,"FormatValueExceptions":false,"check":item.WORKSPACES.Total,"isTotal":"","score":_vm.getScore(item.WORKSPACES, 'WORKSPACES-ACCOUNT')}})]}},{key:"WORKSPACES-NoEncryptionAtRest",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.WORKSPACES.NoEncryptionAtRest,"FormatValueExceptions":true,"check":item.WORKSPACES.NoEncryptionAtRest.Value === 0,"score":_vm.getScore(item.WORKSPACES, 'WORKSPACES-ACCOUNT')}})]}},{key:"WORKSPACES-NoEncryptionRoot",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.WORKSPACES.NoEncryptionRoot,"FormatValueExceptions":true,"check":item.WORKSPACES.NoEncryptionRoot.Value === 0,"score":_vm.getScore(item.WORKSPACES, 'WORKSPACES-ACCOUNT')}})]}},{key:"WORKSPACES-NoEncryptionUser",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.WORKSPACES.NoEncryptionUser,"FormatValueExceptions":true,"check":item.WORKSPACES.NoEncryptionUser.Value === 0,"score":_vm.getScore(item.WORKSPACES, 'WORKSPACES-ACCOUNT')}})]}},{key:"WORKSPACES-Public",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.WORKSPACES.Public,"FormatValueExceptions":true,"check":item.WORKSPACES.Public.Value === 0,"score":_vm.getScore(item.WORKSPACES, 'WORKSPACES-ACCOUNT')}})]}},{key:"GLUE",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.GLUE, 'GLUE-ACCOUNT', 0),"toggle":_vm.toggle.GLUE},on:{"show-hide":function($event){return _vm.toggleRows('GLUE')}}})]}},{key:"GLUE-Total",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.GLUE.Total,"FormatValueExceptions":false,"check":item.GLUE.Total,"isTotal":"","score":_vm.getScore(item.GLUE, 'GLUE-ACCOUNT', 0)}})]}},{key:"GLUE-NoEncryptionAtRest",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.GLUE.NoEncryptionAtRest,"FormatValueExceptions":true,"check":item.GLUE.NoEncryptionAtRest.Value === 0,"score":_vm.getScore(item.GLUE, 'GLUE-ACCOUNT', 0)}})]}},{key:"GLUE-NoEncryptionInTransit",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.GLUE.NoEncryptionInTransit,"FormatValueExceptions":true,"check":item.GLUE.NoEncryptionInTransit.Value === 0,"score":_vm.getScore(item.GLUE, 'GLUE-ACCOUNT', 0)}})]}},{key:"GLUE-NoConnectionPasswordEncryption",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.GLUE.NoConnectionPasswordEncryption,"FormatValueExceptions":true,"check":item.GLUE.NoConnectionPasswordEncryption.Value === 0,"score":_vm.getScore(item.GLUE, 'GLUE-ACCOUNT', 0)}})]}},{key:"STORAGEGATEWAY",fn:function(ref){
              var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY-ACCOUNT', 0),"toggle":_vm.toggle.STORAGEGATEWAY},on:{"show-hide":function($event){return _vm.toggleRows('STORAGEGATEWAY')}}})]}},{key:"STORAGEGATEWAY-Total",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.STORAGEGATEWAY.Total,"FormatValueExceptions":false,"check":item.STORAGEGATEWAY.Total,"isTotal":"","score":_vm.getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY-ACCOUNT', 0)}})]}},{key:"STORAGEGATEWAY-VOL-NoEncryptionAtRest",fn:function(ref){
              var item = ref.item;
return [_c('show-data',{attrs:{"data":item.STORAGEGATEWAY.STORAGEGATEWAYVOLUME.NoEncryptionAtRest,"FormatValueExceptions":true,"check":item.STORAGEGATEWAY.STORAGEGATEWAYVOLUME.NoEncryptionAtRest
                .Value === 0,"score":_vm.getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY-ACCOUNT', 0)}})]}},{key:"STORAGEGATEWAY-VOL-Total",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.STORAGEGATEWAY.STORAGEGATEWAYVOLUME.Total,"FormatValueExceptions":false,"check":item.STORAGEGATEWAY.STORAGEGATEWAYVOLUME.Total,"isTotal":"","score":_vm.getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY-ACCOUNT', 0)}})]}},{key:"STORAGEGATEWAY-TAPE-NoEncryptionAtRest",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.STORAGEGATEWAY.STORAGEGATEWAYTAPE.NoEncryptionAtRest,"FormatValueExceptions":true,"check":item.STORAGEGATEWAY.STORAGEGATEWAYTAPE.NoEncryptionAtRest
                .Value === 0,"score":_vm.getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY-ACCOUNT', 0)}})]}},{key:"STORAGEGATEWAY-TAPE-Total",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.STORAGEGATEWAY.STORAGEGATEWAYTAPE.Total,"FormatValueExceptions":false,"check":item.STORAGEGATEWAY.STORAGEGATEWAYTAPE.Total,"isTotal":"","score":_vm.getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY-ACCOUNT', 0)}})]}},{key:"STORAGEGATEWAY-SHARE-Total",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE.Total,"FormatValueExceptions":false,"check":item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE.Total,"isTotal":"","score":_vm.getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY-ACCOUNT', 0)}})]}},{key:"STORAGEGATEWAY-SHARE-NoEncryptionAtRest",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE.NoEncryptionAtRest,"FormatValueExceptions":true,"check":item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE.NoEncryptionAtRest
                .Value === 0,"score":_vm.getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY-ACCOUNT', 0)}})]}},{key:"STORAGEGATEWAY-SHARE-NoEncryptionInTransit",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE
                .NoEncryptionInTransit,"FormatValueExceptions":true,"check":item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE
                .NoEncryptionInTransit.Value === 0,"score":_vm.getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY-ACCOUNT', 0)}})]}},{key:"STORAGEGATEWAY-SHARE-NoGuestPassword",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE.NoGuestPassword,"FormatValueExceptions":true,"check":item.STORAGEGATEWAY.STORAGEGATEWAYFILESHARE.NoGuestPassword
                .Value === 0,"score":_vm.getScore(item.STORAGEGATEWAY, 'STORAGEGATEWAY-ACCOUNT', 0)}})]}},{key:"VPC",fn:function(ref){
                var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.VPC, 'VPC-Account', 0),"toggle":_vm.toggle.VPC},on:{"show-hide":function($event){return _vm.toggleRows('VPC')}}})]}},{key:"VPC-Total",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.VPC.Total,"FormatValueExceptions":false,"check":item.VPC.Total,"isTotal":"","score":_vm.getScore(item.VPC, 'VPC-Account', 0)}})]}},{key:"VPC-Cleaning",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.VPC.Cleaning,"FormatValueExceptions":true,"check":item.VPC.Cleaning.Value === 0,"score":_vm.getScore(item.VPC, 'VPC-Account', 0)}})]}},{key:"VPC-NonCompliantIP",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.VPC.NonCompliantIP,"FormatValueExceptions":true,"check":item.VPC.NonCompliantIP.Value === 0,"score":_vm.getScore(item.VPC, 'VPC-Account', 0)}})]}},{key:"NETWORKFIREWALL",fn:function(ref){
                var item = ref.item;
return [_c('show-score',{attrs:{"score":_vm.getScore(item.NETWORKFIREWALL, 'NETWORKFIREWALL-Account', 0),"toggle":_vm.toggle.NETWORKFIREWALL},on:{"show-hide":function($event){return _vm.toggleRows('NETWORKFIREWALL')}}})]}},{key:"NETWORKFIREWALL-Total",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.NETWORKFIREWALL.Total,"FormatValueExceptions":false,"check":item.NETWORKFIREWALL.Total,"isTotal":"","score":_vm.getScore(item.NETWORKFIREWALL, 'NETWORKFIREWALL-Account', 0)}})]}},{key:"NETWORKFIREWALL-LoggingDisabled",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.NETWORKFIREWALL.LoggingDisabled,"FormatValueExceptions":true,"check":item.NETWORKFIREWALL.LoggingDisabled.Value === 0,"score":_vm.getScore(item.NETWORKFIREWALL, 'NETWORKFIREWALL-Account', 0)}})]}},{key:"NETWORKFIREWALL-AllowAnyAny",fn:function(ref){
                var item = ref.item;
return [_c('show-data',{attrs:{"data":item.NETWORKFIREWALL.AllowAnyAny,"FormatValueExceptions":true,"check":item.NETWORKFIREWALL.AllowAnyAny.Value === 0,"score":_vm.getScore(item.NETWORKFIREWALL, 'NETWORKFIREWALL-Account', 0)}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }